import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchColorsAction } from "../../../redux/slices/categories/colorsSlice";
import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import LoadingComponent from "../../LoadingComp/LoadingComponent";
import NoDataFound from "../../NoDataFound/NoDataFound";
import { Container, Row, Col, Table, Button, Card } from "react-bootstrap";

export default function ColorsList() {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(fetchColorsAction());
  }, [dispatch]);
  
  const {
    colors: { colors },
    loading,
    error,
  } = useSelector((state) => state?.colors);

  return (
    <Container className="py-4">
      <Row className="align-items-center mb-4">
        <Col>
          <h1 className="h4">All Colors Categories [{colors?.length}]</h1>
          <p className="text-muted">
            A list of all the color categories, including their name and creation date.
          </p>
        </Col>
        <Col className="text-end">
          <Link to="/admin/add-category">
            <Button variant="primary">Add New Category</Button>
          </Link>
        </Col>
      </Row>

      {loading ? (
        <LoadingComponent />
      ) : error ? (
        <ErrorMsg message={error?.message} />
      ) : colors?.length <= 0 ? (
        <NoDataFound />
      ) : (
        <Card>
          <Card.Body>
            <Table responsive bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {colors?.map((color) => (
                  <tr key={color?._id}>
                    <td>{color?.name}</td>
                    <td>{new Date(color?.createdAt).toLocaleDateString()}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
    </Container>
  );
}
