import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, Col, Container, Form, Image, ListGroup, Row, Spinner } from "react-bootstrap";
import {
  changeOrderItemQty,
  getCartItemsFromLocalStorageAction,
  removeOrderItemQty,
} from "../../../redux/slices/cart/cartSlices";
import { fetchCouponAction } from "../../../redux/slices/coupons/couponsSlice";
import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import SuccessMsg from "../../SuccessMsg/SuccessMsg";
import { PageLayout } from "../../../components/page-layout";
import { FaTrash } from "react-icons/fa";

export default function ShoppingCart() {
  // Dispatch setup
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCartItemsFromLocalStorageAction(true));
  }, [dispatch]);

  // Coupon state
  const [couponCode, setCouponCode] = useState("");

  const applyCouponSubmit = (e) => {
    e.preventDefault();
    dispatch(fetchCouponAction(couponCode));
    setCouponCode("");
  };

  // Get store data
  const { coupon, loading, error, isAdded } = useSelector((state) => state?.coupons);
  const { cartItems } = useSelector((state) => state?.carts);

  // Calculate total price
  let sumTotalPrice = cartItems?.reduce((acc, current) => acc + current?.totalPrice, 0);
  if (coupon) {
    sumTotalPrice = sumTotalPrice - (sumTotalPrice * coupon?.coupon?.discount) / 100;
  }

  // Quantity and removal handlers
  const changeOrderItemQtyHandler = (productId, qty) => {
    dispatch(changeOrderItemQty({ productId, qty }));
    dispatch(getCartItemsFromLocalStorageAction());
  };

  const removeOrderItemQtyHandler = (productId) => {
    dispatch(removeOrderItemQty(productId));
    dispatch(getCartItemsFromLocalStorageAction());
  };

  return (
      <div className="container">
        {cartItems?.length > 0 ? (<Row>
          {/* Cart Items */}
          <Col>
            <Card className="mb-4">
              <Card.Body>
                <ListGroup variant="flush">
                  {cartItems?.map((product) => (
                    <>
                    <Row>
                    <ListGroup.Item key={product._id} className="d-flex align-items-center">
                      <Row>
                        <h6 className="mb-1">{product.name}</h6>
                        <small>
                          {product.color}, {product.size}
                        </small>
                        </Row>
                      <Col>
                      <Image
                        src={product.image}
                        alt={product.name}
                        rounded
                        style={{ width: "80px", height: "80px", objectFit: "cover" }}
                        className="me-3"
                      />
                      </Col>
                      <Col>
                        <p className="mt-2 mb-0">
                          £{product.price} x {product.qty} = £{product.totalPrice}
                        </p>
                      </Col>
                      <Col style={{display:"contents"}}>
                      <Form.Select
                        style={{ width: "80px" }}
                        value={product.qty}
                        onChange={(e) => changeOrderItemQtyHandler(product?._id, e.target.value)}>
                        {[...Array(product?.qtyLeft)?.keys()]?.map((x) => (
                          <option key={x} value={x + 1}>
                            {x + 1}
                          </option>
                        ))}
                      </Form.Select>
                      <Button
                        variant="danger"
                        className="ms-3"
                        onClick={() => removeOrderItemQtyHandler(product?._id)}>
                        <FaTrash />
                      </Button>
                      </Col>
                    </ListGroup.Item>
                    </Row>
                    </>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>

          {/* Order Summary */}
          <Col>
            <Card>
              <Card.Body>
                <h4 className="mb-4">Order Summary</h4>
                <ListGroup variant="flush">
                  <ListGroup.Item className="d-flex justify-content-between">
                    <span>Subtotal</span>
                    <strong>£{sumTotalPrice.toFixed(2)}</strong>
                  </ListGroup.Item>
                  {coupon && (
                    <ListGroup.Item className="d-flex justify-content-between">
                      <span>Discount</span>
                      <strong>{coupon?.coupon?.discount}%</strong>
                    </ListGroup.Item>
                  )}
                  <ListGroup.Item className="d-flex justify-content-between">
                    <span>Order Total</span>
                    <strong>£{sumTotalPrice.toFixed(2)}</strong>
                  </ListGroup.Item>
                </ListGroup>

                {/* Coupon Section */}
                <Form onSubmit={applyCouponSubmit} className="mt-4">
                  <Form.Group controlId="couponCode">
                    <Form.Label>Have a coupon code?</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter coupon code"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                    />
                  </Form.Group>
                  <Button
                    type="submit"
                    variant="success"
                    className="mt-3 w-100">
                    {loading ? <Spinner animation="border" size="sm" /> : "Apply Coupon"}
                  </Button>
                </Form>
                {error && <ErrorMsg message={error?.message} />}
                {isAdded && (
                  <SuccessMsg message={`Congratulations! You received ${coupon?.coupon?.discount}% off.`} />
                )}

                {/* Checkout Button */}
                <Button
                  as={Link}
                  to="/order-payment"
                  state={{ sumTotalPrice }}
                  variant="primary"
                  className="mt-4 w-100">
                  Proceed to Checkout
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>) : 
        (
          <Row>
            <p>Your basket is currently empty</p>
          </Row>
        )
        }
      </div>
  );
}
