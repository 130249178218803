import { Link } from "react-router-dom";
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import "../../styling/Topics.css";
import LoadingWheel from "../loading";
import topicsImageHeader from "../../static/topicsImage.jpg";
import { Card, Col, Row } from "react-bootstrap";

export default function DisplayAllTopics() {
  const [topicsData, setTopicsData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/v1/topics/`);
        setTopicsData(response.data);
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Error fetching topics:', error);
        }
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  if (loading) return <LoadingWheel />;

  if (!topicsData || topicsData.length === 0) {
    return (
      <>
        <h1>Sorry, we cannot find what you are looking for!</h1>
        <p>Please try again later</p>
      </>
    );
  }

  return (
    <>
      <Link className="addResourceBtn" to="/Resources/Create" style={{ marginLeft: "20px" }}>+Resource</Link>

      <div style={{ padding: "20px" }}>
        <Row className="g-3">
          {topicsData.map((item) => {
            const link = item.name === "All Resources" ? "/resources" : `/topic/${item.slug}`;

            return (
              <Col key={item.name} xs={6} sm={6} md={4} lg={3}>
                <Link to={link} style={{ textDecoration: "none" }}>
                  <Card className="text-white">
                    <Card.Img
                      src={item.images?.[0]?.file || topicsImageHeader}
                      alt={item.name || "Topic Image"}
                      style={{ height: "180px", objectFit: "cover", borderRadius: "5px" }}
                    />
                    <Card.ImgOverlay className="d-flex flex-column justify-content-end bg-dark bg-opacity-50" style={{ borderRadius: "5px" }}>
                      <Card.Title className="fw-bold text-shadow p-0 m-0 fs-6">{item.name}</Card.Title>
                      <Card.Text className="text-shadow">{item.numOfResources && item.numOfResources+" resources"}</Card.Text>
                    </Card.ImgOverlay>
                  </Card>
                </Link>
              </Col>
            );
          })}
        </Row>
      </div>
    </>
  );
}
