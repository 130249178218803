import React, { useState } from 'react';
import '../../styling/UserTab.css';
import DisplayAllResources from '../map_displays/displayAllResources';
import CustomerProfile from '../../storeComponents/Users/Profile/OrdersPage'
const UserTab = ({ user }) => {
  const [activeTab, setActiveTab] = useState('Account');

  const openTab = (userDetails) => {
    setActiveTab(userDetails);
  };

  return (
    <div className='container-flex'>
      <div className="tab">
        <button
          className={`tablinks ${activeTab === 'Account' ? 'active' : ''}`}
          onClick={() => openTab('Account')}
        >
          Account
        </button>
        <button
          className={`tablinks ${activeTab === 'Activity' ? 'active' : ''}`}
          onClick={() => openTab('Activity')}
        >
          Activity
        </button>
        <button
          className={`tablinks ${activeTab === 'Orders' ? 'active' : ''}`}
          onClick={() => openTab('Orders')}
        >
          Orders
        </button>
        
      </div>

      <div id="Account" className={`tabcontent ${activeTab === 'Account' ? 'show' : ''}`}>
        <h3>Account</h3>
        <p>Manage your account details here. To Update or Delete your account please use the contact us form. Passwords can be updated using the "Forgot my password" link on sign in</p>
        <p>Email: {user.email}</p>
        <p>Username: {user.username}</p>
      </div>

      <div id="Activity" className={`tabcontent ${activeTab === 'Activity' ? 'show' : ''}`}>
        <h3>Activity</h3>
        <p>View your recent activity here.</p>
        <DisplayAllResources urlPassed={`/v1/items/user/`+user.username} dataPassed={true} />
      </div>

      <div id="Orders" className={`tabcontent ${activeTab === 'Orders' ? 'show' : ''}`}>
        <h3>Orders</h3>
        <CustomerProfile />
      </div>
    </div>
  );
};

export default UserTab;