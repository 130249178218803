import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { createBrandAction } from "../../../redux/slices/categories/brandsSlice";
import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import LoadingComponent from "../../LoadingComp/LoadingComponent";
import SuccessMsg from "../../SuccessMsg/SuccessMsg";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";

export default function AddBrand() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ name: "" });

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    dispatch(createBrandAction(formData.name));
    setFormData({ name: "" });
  };

  const { error, loading, isAdded } = useSelector((state) => state.brands);

  return (
    <Container className="py-5">
      {isAdded && <SuccessMsg message="Brand Created Successfully" />}
      {error && <ErrorMsg message={error.message} />}

      <Row className="justify-content-center">
        <Col md={6}>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">Add Product Brand</h2>
              <Form onSubmit={handleOnSubmit}>
                <Form.Group controlId="brandName" className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleOnChange}
                    placeholder="Enter brand name"
                  />
                </Form.Group>

                {loading ? (
                  <LoadingComponent />
                ) : (
                  <Button type="submit" variant="primary" className="w-100">
                    Add Product Brand
                  </Button>
                )}
              </Form>

              <div className="mt-4">
                <Row>
                  <Col>
                    <Link to="/admin/add-brand">
                      <Button variant="secondary" className="w-100">
                        Add Brand
                      </Button>
                    </Link>
                  </Col>
                  <Col>
                    <Link to="/admin/add-color">
                      <Button variant="secondary" className="w-100">
                        Add Color
                      </Button>
                    </Link>
                  </Col>
                  <Col>
                    <Link to="/admin/add-category">
                      <Button variant="secondary" className="w-100">
                        Add Category
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
