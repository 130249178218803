import React from 'react';
import DisplayAllResources from '../../components/map_displays/displayAllResources';
import "../../styling/Resources.css"
import topicsImageHeader from "../../static/topicsImage.jpg";
import HeroImage from '../../components/hero';
import { PageLayout } from "../../components/page-layout";

function Resources() {
  return (
    <PageLayout>
      <div className="container-flex">
        <HeroImage imagepath={topicsImageHeader} header="Resources" subheader="View All Resources" pad='20px' ></HeroImage>           
        <br />
        <DisplayAllResources dataPassed={false} />
      </div>
    </PageLayout>
  );
}

//export Items
export default Resources;
