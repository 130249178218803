import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileAction } from "../../../redux/slices/users/usersSlice";
import { Row, Col, Image, Spinner, Alert, Button } from "react-bootstrap";

export default function CustomerProfile() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  const { error, loading, profile } = useSelector((state) => state?.users);
  const orders = profile?.user?.orders;

  const isInCancelTimeFrame = (createdOn) => {
    const createdTime = new Date(createdOn);
    const currentTime = new Date();
    const timeDifference = currentTime - createdTime; // Time difference in milliseconds
    return timeDifference <= 30 * 60 * 1000; // 30 minutes in milliseconds
  };

  return (
    <>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : error ? (
        <Alert variant="danger">{error?.message}</Alert>
      ) : orders?.length <= 0 ? (
        <h4 className="text-center mt-4">No Order Found</h4>
      ) : (
        orders?.map((order) => (
          <>

          {order?.paymentStatus === "paid" && order?.orderItems?.map((product, index) => (

              <Row style={{borderBottom:"1px solid black", marginBottom:"10px"}} key={index}>
              <Col>
                <Image src={product.image} alt={product.name} style={{ width: "100px", height: "100px",justifyContent:"center", objectFit: "cover" }} rounded/>  
              </Col>
              <Col>
                <h6>{product.name}</h6>              
                <p>
                  Order: {order?.orderNumber}<br/>
                  Date Ordered: <span>{new Date(order?.createdAt).toDateString()}</span><br/>
                  Paid: ${product.totalPrice * product.qty}<br/>
                  Status: {order?.status}<br/>
                  <Button href={`/contact?subject=${order?.orderNumber}`} style={{ marginRight: "10px" }}>
                    Order Enquiry
                  </Button>
                </p>
              </Col>
            </Row>
          ))}
          </>
        ))
      )}
    </> 
  );
}

