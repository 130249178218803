import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OrdersStatsAction } from "../../../redux/slices/orders/ordersSlices";
import { Card, Container, Row, Col } from "react-bootstrap";
import { FaChartLine } from "react-icons/fa";

export default function OrdersStats() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(OrdersStatsAction());
  }, [dispatch]);

  const { stats, loading, error } = useSelector((state) => state?.orders);

  const obj = stats?.orders || [];
  const statistics = obj.length > 0 ? Object.values(obj[0]) : []; 

  const renderStatCard = (title, value, bgColor) => (
    <Card className={`text-white mb-4`} style={{ backgroundColor: bgColor }}>
      <Card.Body>
        <Row className="align-items-center">
          <Col xs={3} className="d-flex justify-content-center">
            <div className="p-2 rounded-circle bg-dark">
              <FaChartLine size={24} className="text-white" />
            </div>
          </Col>
          <Col xs={9}>
            <Card.Title className="fs-6">{title}</Card.Title>
            <Card.Text className="fs-4">{value}</Card.Text>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );


//stats?.saleToday?.length > 0 ? stats.saleToday[0] : "£0.00"
  return (
    <Container className="mt-5">
      <Row xs={1} sm={2} lg={4}>
        {renderStatCard("Today's Sales", `£${statistics[4] || 0}`,"#4C51BF")}
        {renderStatCard("Minimum Order", `£${statistics[1] || 0}`, "#C53030")}
        {renderStatCard("Maximum Order", `£${statistics[3] || 0}`, "#D69E2E")}
        {renderStatCard("Total Sales", `£${statistics[2] || 0}`, "#48BB78")}
      </Row>
    </Container>
  );
}
