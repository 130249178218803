import React, { useEffect, useState, useRef } from "react";
import { PageLayout } from "../../components/page-layout";
import { Button, ButtonGroup, ToggleButton } from "react-bootstrap";
import HeroImage from "../../components/hero";
import topicsImageHeader from "../../static/topicsImage.jpg";
import { ProgressBar } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const questions = [
  { text: "I prefer to do things with others rather than on my own.", reverse: true },
  { text: "I prefer to do things the same way over and over again.", reverse: false },
  { text: "If I try to imagine something, I find it easy to create a picture in my mind.", reverse: true },
  { text: "I frequently get so strongly absorbed in one thing that I lose sight of other things.", reverse: false },
  { text: "I often notice small sounds when others do not.", reverse: false },
  { text: "I usually notice car number plates or similar strings of information.", reverse: false },
  { text: "Other people frequently tell me that what I’ve said is impolite, even though I think it is polite.", reverse: false },
  { text: "When I’m reading a story, I can easily imagine what the characters might look like.", reverse: true },
  { text: "I find it easy to work out what someone is thinking or feeling just by looking at their face.", reverse: true },
  { text: "I find it difficult to work out people’s intentions.", reverse: false },
  { text: "I enjoy social occasions.", reverse: true },
  { text: "I find it difficult to handle multiple tasks at once.", reverse: false },
  { text: "I am fascinated by dates.", reverse: false },
  { text: "In a social group, I can easily keep track of several different people’s conversations.", reverse: true },
  { text: "I find social situations easy.", reverse: true },
  { text: "I tend to notice details that others do not.", reverse: false },
  { text: "I would rather go to a library than a party.", reverse: false },
  { text: "I find making up stories easy.", reverse: true },
  { text: "I find myself drawn more strongly to people than to things.", reverse: true },
  { text: "I tend to have very strong interests, which I get upset about if I can’t pursue.", reverse: false },
  { text: "I enjoy social chit-chat.", reverse: true },
  { text: "When I talk, it isn’t always easy for others to get a word in edgeways.", reverse: false },
  { text: "I am fascinated by numbers.", reverse: false },
  { text: "When I’m reading a story, I find it difficult to work out the characters’ intentions.", reverse: false },
  { text: "I don’t particularly enjoy reading fiction.", reverse: false },
  { text: "I find it hard to make new friends.", reverse: false },
  { text: "I notice patterns in things all the time.", reverse: false },
  { text: "I would rather go to the theater than a museum.", reverse: true },
  { text: "It does not upset me if my daily routine is disturbed.", reverse: true },
  { text: "I frequently find that I don’t know how to keep a conversation going.", reverse: false },
  { text: "I find it easy to ‘read between the lines’ when someone is talking to me.", reverse: true },
  { text: "I usually concentrate more on the whole picture, rather than the small details.", reverse: true },
  { text: "I am not very good at remembering phone numbers.", reverse: false },
  { text: "I don’t usually notice small changes in a situation or a person’s appearance.", reverse: false },
  { text: "I know how to tell if someone listening to me is getting bored.", reverse: true },
  { text: "I find it easy to do more than one thing at once.", reverse: true },
  { text: "When I talk on the phone, I’m not sure when it’s my turn to speak.", reverse: false },
  { text: "I enjoy doing things spontaneously.", reverse: true },
  { text: "I am often the last to understand the point of a joke.", reverse: false },
  { text: "I find it easy to work out what someone is thinking or feeling.", reverse: true },
  { text: "If there is an interruption, I can switch back to what I was doing very quickly.", reverse: true },
  { text: "I am good at social chit-chat.", reverse: true },
  { text: "People often tell me that I keep going on and on about the same thing.", reverse: false },
  { text: "When I was young, I used to enjoy playing games involving pretending with other children.", reverse: true },
  { text: "I like to collect information about categories of things (e.g., types of car, bird species, etc.).", reverse: false },
  { text: "I find it difficult to imagine what it would be like to be someone else.", reverse: false },
  { text: "I like to carefully plan any activities I participate in.", reverse: false },
  { text: "I enjoy social occasions.", reverse: true },
  { text: "I find it difficult to imagine what it would be like to be someone else.", reverse: false },
  { text: "I enjoy socializing.", reverse: true }
];

const perPage = 10;
const chunkedQuestions = [];
for (let i = 0; i < questions.length; i += perPage) {
  chunkedQuestions.push(questions.slice(i, i + perPage));
}

const AQTest = () => {
  const [answers, setAnswers] = useState(new Array(questions.length).fill(null)); // Store answers for all questions
  const [showScore, setShowScore] = useState(false);
  const [email, setEmail] = useState('');
  const [result, setResult] = useState(0);
  const [addedMailList, setAddedEmailList] = useState(false);
  const [showStartScreen, setShowStartScreen] = useState(true);
  const [showDisclaimerScreen, setShowDisclaimerScreen] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);
  const [nowAsPercent, setNowAsPercent] = useState(0);

  const { isAuthenticated } = useAuth0();
  const currentPageRef = useRef(0); // Use a ref to keep track of the current page

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isAuthenticated) {
      setAddedEmailList(true);
    }
  }, [isAuthenticated]);

  const handleAnswerChange = (index, value) => {
    setAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers];
      newAnswers[index] = value;
      return newAnswers;
    });
  };

  const calculateScore = () => {
    return answers.reduce((score, answer, index) => {
      const isReverse = questions[index]?.reverse;
      if (answer !== null) {
        score += isReverse ? (answer === 0 ? 1 : 0) : answer;
      }
      return score;
    }, 0);
  };

  const calculateProgress = () => {
    const answered = answers.filter((answer) => answer !== null).length;
    return (answered / questions.length) * 100;
  };

  useEffect(() => {
    setNowAsPercent(Math.round(calculateProgress()));
  }, [answers]);

  const handleSubmit = () => {
    if (currentPageRef.current === chunkedQuestions.length - 1) {
      submitQuizClick();
    } else {
      currentPageRef.current += 1;
    }
  };

  const startAssessmenClick = () => {
    setShowStartScreen(false);
    setShowDisclaimerScreen(true);
  };

  const acceptDisclaimerClick = () => {
    setShowDisclaimerScreen(false);
    setShowQuiz(true);
  };

  const submitQuizClick = () => {
    setResult(calculateScore());
    setShowQuiz(false);
    setShowScore(true);
  };

  const previousPage = () => {
    if (currentPageRef.current > 0) {
      currentPageRef.current -= 1;
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      alert("Please enter a valid email");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("email", email);
      await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/v1/users/email/subscribe`, formData);
      sessionStorage.setItem("addedEmailListAQ", true);
      setAddedEmailList(true);
    } catch (error) {
      setAddedEmailList(true);
    }
  };

  return (
    <PageLayout>
      <HeroImage imagepath={topicsImageHeader} header="AQ Assessment" subheader="Autism Spectrum Quotient Assessment" pad="20px" />

      {showStartScreen && (
        <section id="startScreen" style={{ width: "90%", margin: "5% auto", textAlign: "center" }}>
          <p>
            The AQ (Autism Spectrum Quotient) Assessment was created by psychologist Simon Baron-Cohen and his colleagues at the Autism Research Centre at the University of Cambridge in 2001. The assessment is designed to measure the extent of autistic traits in adults and is often used in both research and clinical settings as an initial screening tool. It consists of 50 questions aimed at gauging areas like social skills, communication, imagination, and attention to detail.
          </p>
          <div id="startScreenBtns" style={{ margin: "5%" }}>
            <Button onClick={startAssessmenClick}>Start the assessment</Button>
            <p style={{ padding: "10px", margin: "0px" }}>-----OR-----</p>
            <Button disabled>Download (PDF Format)</Button>
          </div>
          <span style={{ fontSize: "0.8rem" }}>Source: Baron-Cohen et al., 2001, Journal of Autism and Developmental Disorders.</span>
        </section>
      )}

      {showDisclaimerScreen && (
        <section id="disclaimerScreen" style={{ width: "90%", margin: "5% auto", textAlign: "center" }}>
          <p style={{ fontSize: "1.2rem" }}>Disclaimer!</p>
          <p>
            While the AQ can offer valuable insights into whether someone exhibits traits consistent with autism, it is important to remember that it is not a diagnostic tool.
          </p>
          <p>
            This assessment is not an official diagnosis. If you have concerns about autism or related conditions, please consult a healthcare professional for a comprehensive evaluation.
          </p>
          <Button onClick={acceptDisclaimerClick}>Acknowledge</Button>
          <p style={{ fontSize: "0.8rem" }}>By clicking "Acknowledge" you agree that you understand the above statement</p>
        </section>
      )}

      {showQuiz && (
        <section style={{ width: "95%", margin: "10px auto" }}>
          <ProgressBar now={nowAsPercent} label={`${nowAsPercent}%`} />
          <div>
            {chunkedQuestions[currentPageRef.current].map((question, index) => (
              <div key={index} style={{ backgroundColor: index % 2 === 1 ? "white" : "#0d6efd36", padding: "2%" }}>
                <p>{question.text}</p>
                <div className="w-100" style={{ maxWidth: "100%", margin: "auto" }}>
                  <ButtonGroup className="w-100">
                    {["Disagree", "Agree"].map((label, value) => (
                      <ToggleButton
                        key={value}
                        id={`radio-${index}-${value}`}
                        type="radio"
                        name={`question-${index + currentPageRef.current * 5}`}
                        variant="outline-primary"
                        value={value}
                        checked={answers[index + currentPageRef.current * 5] === value}
                        onChange={() => handleAnswerChange(index + currentPageRef.current * 5, value)}
                        className="flex-fill text-black py-3 px-0"
                        style={{ minWidth: "18%" }}
                      >
                        {label}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                </div>
              </div>
            ))}
            <div id="navButtonsQuiz" style={{ display: "flex", width: "70%", margin: "2% auto" }}>
              <Button onClick={previousPage} className="pull-right" style={{ width: "40%", margin: "0 5%" }} disabled={currentPageRef.current === 0}>Previous</Button>
              <Button onClick={handleSubmit} className="pull-right" style={{ width: "40%", margin: "0 5%" }} disabled={answers.slice(currentPageRef.current * perPage, (currentPageRef.current + 1) * perPage).includes(null)}>
                {(currentPageRef.current === chunkedQuestions.length - 1) ? "Submit" : "Next"}
              </Button>
            </div>
          </div>
        </section>
      )}

      {showScore && (
        <section>
          <div>
            <ProgressBar now={nowAsPercent} label={`${nowAsPercent}%`} />
          </div>
          <div style={{ width: "90%", margin: "auto", padding: "10px", textAlign: "center" }}>
            {addedMailList ? (
              <div>
                <h3>Your Score: {result} / 50</h3>
                <h4>What does this mean?</h4>
                <ul>
                  <li>26 or higher: May suggest the presence of autism.</li>
                  <li>25 or lower: May suggest the absence of autism.</li>
                  <li>80% of individuals with autism: Typically score 32 or above on the AQ.</li>
                  <li>Average scores for non-autistic individuals: Generally range between 15 and 17.</li>
                </ul>
                <p style={{ fontSize: "0.8rem" }}>This test is not a diagnosis of autism. Reference: Baron-Cohen et al. (2001), The Autism-Spectrum Quotient (AQ): Evidence from Asperger Syndrome/High-Functioning Autism, Males and Females, Scientists and Mathematicians.</p>
                <Button onClick={() => window.location.reload()}>Retake Assessment</Button>
              </div>
            ) : (
              <div>
                <h3>Your Score: ? / 50</h3>
                <p>To receive your detailed results, please join our mailing list or create an account.</p>
                <form onSubmit={handleFormSubmit}>
                  <label>
                    Email:
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </label><br />
                  <button type="submit">Submit</button>
                </form>
              </div>
            )}
          </div>
        </section>
      )}
    </PageLayout>
  );
};

export default AQTest;
