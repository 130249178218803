import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Container, Row, Pagination, Badge } from "react-bootstrap";
import { fetchOrdersAction } from "../../../redux/slices/orders/ordersSlices";
import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import LoadingComponent from "../../LoadingComp/LoadingComponent";
import NoDataFound from "../../NoDataFound/NoDataFound";
import OrdersStats from "./statistics";

export default function OrdersList() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [itemsPerPage] = useState(30); // Set items per page

  useEffect(() => {
    dispatch(fetchOrdersAction({currentPage:currentPage, itemsPerPage: itemsPerPage, showUnpaid: false}));
  }, [dispatch, currentPage, itemsPerPage]);

  // Safeguard: Initialize the orders object to an empty object if it is null or undefined
  const {
    error,
    loading,
    orders = {}, // Defaulting to an empty object
  } = useSelector((state) => state?.orders);

  const orderList = orders?.orders || []; 
  const totalItems = orders?.totalItems || 0;

  const goToFirstPage = () => setCurrentPage(1);
  const goToPrevPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);
  const goToNextPage = () => currentPage < Math.ceil(totalItems / itemsPerPage) && setCurrentPage(currentPage + 1);
  const goToLastPage = () => setCurrentPage(Math.ceil(totalItems / itemsPerPage));


  return (
    <Container>
      {error && <ErrorMsg message={error?.message} />}
      <Row className="mb-4">
        <OrdersStats />
      </Row>

      <h3 className="mt-3">Recent Orders</h3>
      <p>NEED TO KNOW: ITEMS TO REFUND? BY EACH STATUS? BY ID?</p>
      <div className="shadow-sm">
        <Table bordered hover responsive className="mt-3">
          <thead className="bg-light">
            <tr>
              <th>Order ID</th>
              <th className="d-none d-lg-table-cell">Payment Status</th>
              <th className="d-none d-sm-table-cell">Order Date</th>
              <th>Delivery Date</th>
              <th>Status</th>
              <th>Total</th>
              <th>Actions</th>
            </tr>
          </thead>
          {loading ? (
            <LoadingComponent />
          ) : orderList.length <= 0 ? (
            <NoDataFound />
          ) : (
            <tbody>
              {orderList.map((order) => (
                <tr key={order._id}>
                  <td>{order.orderNumber}</td>
                  <td className="d-none d-lg-table-cell">
                    {order.paymentStatus === "Not paid" ? (
                      <Badge bg="danger">{order.paymentStatus}</Badge>
                    ) : (
                      order.paymentStatus
                    )}
                  </td>
                  <td className="d-none d-sm-table-cell">
                    {new Date(order?.createdAt).toLocaleDateString()}
                  </td>
                  <td className="d-none d-lg-table-cell">Unknown</td>
                  <td>{order?.status}</td>
                  <td>{order?.totalPrice}</td>
                  <td>
                    {order?.paymentStatus === "Not paid" ? (
                      <span className="text-muted">Edit</span>
                    ) : (
                      <Link to={`/admin/orders/${order?._id}`} className="text-primary">
                        Edit
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>

      </div>
      <Pagination style={{ margin: "4% auto" }}>
                 <Pagination.First onClick={goToFirstPage} disabled={currentPage === 1} />
                 <Pagination.Prev onClick={goToPrevPage} disabled={currentPage === 1} />
                 <Pagination.Item active>
                   Page {currentPage} of {Math.ceil(totalItems / itemsPerPage)}
                 </Pagination.Item>
                 <Pagination.Next onClick={goToNextPage} disabled={currentPage === Math.ceil(totalItems / itemsPerPage)} />
                 <Pagination.Last onClick={goToLastPage} disabled={currentPage === Math.ceil(totalItems / itemsPerPage)} />
               </Pagination>
    </Container>
  );
}
