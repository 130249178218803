import { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import Swal from "sweetalert2";
import { GlobeAmericasIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductAction } from "../../../redux/slices/products/productSlices";
import { addOrderToCartaction, getCartItemsFromLocalStorageAction } from "../../../redux/slices/cart/cartSlices";
import { PageLayout } from "../../../components/page-layout";
import { Button, Row, Col } from "react-bootstrap";
import { BsStar,BsStarFill, BsStarHalf } from "react-icons/bs";

const policies = [
  {
    name: "International delivery",
    icon: GlobeAmericasIcon,
    description: "Get your order in 2 years",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Product() {
  //dispatch
  const dispatch = useDispatch();
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedColor, setSelectedColor] = useState("");

  const navigate = useNavigate();

  let productDetails = {};

  //get id from params
  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchProductAction(id));
  }, [id]);
  //get data from store
  const { loading, error, product: { product } } = useSelector((state) => state?.products);

  //Get cart items
  useEffect(() => {
    dispatch(getCartItemsFromLocalStorageAction());
  }, []);
  //get data from store
  const { cartItems } = useSelector((state) => state?.carts);
  const productExists = cartItems?.find((item) => item?._id?.toString() === product?._id.toString());

  const BuyNowHandler = async() => {
    await addToCartHandler(true)
    navigate('/order-payment')
  }
  //Add to cart handler
  const addToCartHandler = (buyNow=false) => {
    //check if product is in cart
    if (productExists) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "This product is already in cart",
      });
    }
    //check if color/size selected
    if (!product?.isDigital && selectedColor === "") {
      return Swal.fire({
        icon: "error",
        title: "Oops...!",
        text: "Please select product color",
      });
    }
    if (!product?.isDigital && selectedSize === "") {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select product size",
      });
    }
    dispatch(
      addOrderToCartaction({
        _id: product?._id,
        name: product?.name,
        qty: 1,
        price: product?.price,
        description: product?.description,
        color: selectedColor,
        size: selectedSize,
        image: product?.images[0],
        totalPrice: product?.price,
        qtyLeft: product?.qtyLeft,
        isDigital: product?.isDigital || false,
      })
    );
    if(!buyNow) {
      Swal.fire({
        icon: "success",
        title: "Good Job",
        text: "Product added to cart successfully",
      });
    }

      return dispatch(getCartItemsFromLocalStorageAction());
  
  };

  return (
    <PageLayout style={{overflowX: "hidden"}}>
      <div className="container">
        <Row className="mt-4">
          <Col md={5}>
            <h2 className="sr-only">Images</h2>
            <div className="d-flex flex-column align-items-center">
              {product?.images?.map((image) => (
                <img style={{width:"80%"}} key={image.id} src={image} alt={image.imageAlt} className="rounded-lg mb-3" />
              ))}
            </div>
          </Col>

          {/* Product details on the right */}
          <Col md={6} style={{margin:"auto"}}>
          <div style={{width: "90%", margin: "auto", background: "white", borderRadius: "8px", padding: "27px"}}>
          <h1 className="text-xl font-medium text-gray-900">{product?.name}</h1>
          <div style={{display:"none"}}> 
              <h2 className="sr-only">Reviews</h2>
              <div className="d-flex align-items-center">
                <p className="text-sm text-gray-700">
                  {product?.reviews?.length}
                </p>
                <div className="ml-1 d-flex align-items-center">
                  {[0, 1, 2, 3, 4].map((rating) => {
                    if (rating + 1 <= product?.averageRating) {
                      return <BsStarFill key={rating} />;
                    } else if (rating + 1 <= product?.averageRating && product?.averageRating.contains(".5")) {
                      return <BsStarHalf key={rating} />;
                    } else {
                      return <BsStar key={rating} />;
                    }
                  })}
                </div>
              </div>
              
            </div>
          <p className="mt-2"> £ {product?.price} </p>
          <p className="text-gray-500 mt-2">{product?.description}</p>
           

        

      
    {!product?.isDigital && (
      <>
      <div className="mt-4">
        <h2 className="text-sm font-medium text-gray-900">Color</h2>
          <RadioGroup value={selectedColor} onChange={setSelectedColor}>
            <div className="d-flex flex-wrap">
          
        {product?.colors?.map((color) => (
          <RadioGroup.Option
            key={color}
            value={color}
            className="m-1"
          >
          {({ checked }) => (
            <span
              style={{
                backgroundColor: color,
                display: "inline-block",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                border: checked ? "2px solid blue" : "1px solid black",
              }}
            />
          )}  
          </RadioGroup.Option>
        ))}
      </div>      
      </RadioGroup>
      </div>

            <div className="mt-4">
              <h2 className="text-sm font-medium text-gray-900">Size</h2>
              <RadioGroup value={selectedSize} onChange={setSelectedSize}>
                <div className="d-flex flex-wrap">
                  {product?.sizes?.map((size) => (
                    <RadioGroup.Option
                      key={size}
                      value={size}
                      className={({ active, checked }) =>
                        classNames(
                          checked
                            ? "bg-indigo-600 border-transparent text-white"
                            : "bg-white border-gray-200 text-gray-900",
                          "border rounded-md py-2 px-3 text-sm font-medium uppercase cursor-pointer m-1"
                        )
                      }
                    >
                      <RadioGroup.Label as="span">{size}</RadioGroup.Label>
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>
            </>
)}  

            {!productExists && (
              <Button
                onClick={BuyNowHandler}
                variant="primary"
                className="w-100 mt-3"
              >
                Buy Now
              </Button>
            )}

            {/* Add to cart button */}
            {product?.qtyLeft <= 0 ? (
              <Button disabled variant="secondary" className="w-100 mt-3">
                Out of stock
              </Button>
            ) : (
              <div id="actionButtons">
              
              <Button
                onClick={addToCartHandler}
                variant="primary"
                className="w-100 mt-3"
              >
                Add to cart
              </Button>
              </div>
            )}
            
            {/* Proceed to checkout button */}
            {cartItems.length > 0 && (
              <Link to="/basket">
                <Button
                  variant="success"
                  className="w-100 mt-3"
                >
                  Proceed to checkout
                </Button>
              </Link>
            )}
            </div>
          </Col>
        </Row>


        {/* Reviews */}
        <section aria-labelledby="reviews-heading" className="sm:mt-24 my-4" style={{display:"none",borderRadius:"8px", backgroundColor:"rgba(0,0,0,0.1)", margin:"10px", padding:"10px"}}>
          <h2
            id="reviews-heading"
            className="text-lg font-medium text-gray-900">
            Recent reviews
          </h2>
          <Link to={`/add-review/${product?._id}`} style={{fontSize:"1rem",color:"black","textDecoration":"none"}} className="mt-1"> Leave a review </Link>

          <div className="mt-6 space-y-10 divide-y divide-gray-200 border-t border-b border-gray-200 pb-10">
            {product?.reviews.map((review) => (
              <div
                key={review._id}
                className="pt-10 lg:grid lg:grid-cols-12 lg:gap-x-8">
                <div className="lg:col-span-8 lg:col-start-5 xl:col-span-9 xl:col-start-4 xl:grid xl:grid-cols-3 xl:items-start xl:gap-x-8">
                  <div className="flex items-center xl:col-span-1">
                    <div className="flex items-center">
                      {[0, 1, 2, 3, 4].map((rating) => (
                        <BsStar
                          key={rating}
                          className={classNames(
                            review.rating > rating
                              ? "text-yellow-400"
                              : "text-gray-200",
                            "h-5 w-5 flex-shrink-0"
                          )}
                          aria-hidden="true"
                        />
                      ))}
                    </div>
                    <p className="ml-3 text-sm text-gray-700">
                      {review.rating}
                      <span className="sr-only"> out of 5 stars</span>
                    </p>
                  </div>

                  <div className="mt-4 lg:mt-6 xl:col-span-2 xl:mt-0">
                    <h3 className="text-sm font-medium text-gray-900">
                      {review?.message}
                    </h3>
                  </div>
                </div>

                <div className="mt-6 flex items-center text-sm lg:col-span-4 lg:col-start-1 lg:row-start-1 lg:mt-0 lg:flex-col lg:items-start xl:col-span-3">
                  <p className="font-medium text-gray-900">
                    {review.user?.fullname}
                  </p>
                  <time
                    dateTime={review.datetime}
                    className="ml-4 border-l border-gray-200 pl-4 text-gray-500 lg:ml-0 lg:mt-2 lg:border-0 lg:pl-0">
                    {new Date(review.createdAt).toLocaleDateString()}
                  </time>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </PageLayout>
  );
}
