import React from 'react';
import CreateItem from '../../components/forms/CreateItem';
import "../../styling/Resources.css"
import { PageLayout } from "../../components/page-layout";

function ResourcesAdd() {
  
  return (
    <PageLayout>
      <CreateItem title={"Create"} />
    </PageLayout>
  );
}

//export Items
export default ResourcesAdd;


/**
 * 
 * //get and set for the items and topics in the database
  const [itemsData, setItemsData] = useState([]);
  const [loading, setLoading] = useState(false)
  
  useEffect(() => {
      //set loading to true to display the loading screen
      setLoading(true)
      //call the API to get the users items
      axios.get(`${process.env.REACT_APP_API_SERVER_URL}/v1/items/`)
      .then(response => {
        //set itemsData with the response 
        const items = response.data;
        setItemsData(items)
      })
      //catch if the call fails
      .catch(error => {
        //log any errors
        console.error('Error fetching item:', error);
      })
      //once the call is complete
      .finally(() => {
        //set loading to false
          setLoading(false)
      });  
    
  }, []);


//if the page is loading
  if(loading) {
    //display loading message
    return (<>
    <LoadingWheel />
    </>)
}
//if the page isnt loading 
else {

//if there are no items
if (!itemsData) {
  //display a 404 error
  return (
    <>
      <h1>USE 404 HERE</h1>
    </>
  );
}
 * 
 */