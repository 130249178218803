import { useState } from "react";
import { Link } from "react-router-dom";
import ErrorComponent from "../../ErrorMsg/ErrorMsg";
import SuccessMsg from "../../SuccessMsg/SuccessMsg";
import LoadingComponent from "../../LoadingComp/LoadingComponent";
import { Container, Row, Col, Form, Button, Alert, Card } from "react-bootstrap";

export default function UpdateCategory() {
  //---form data---
  const [formData, setFormData] = useState({
    name: categoryName,
  });

  //---onChange---
  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  let loading, error, isUpdated, categoryName;

  //onSubmit
  const handleOnSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Container className="d-flex flex-column justify-content-center min-vh-100 py-4">
      {error && <ErrorComponent message={error?.message} />}
      {isUpdated && <SuccessMsg message="Category updated successfully" />}

      <Row className="justify-content-center">
        <Col xs={12} sm={8} md={6} lg={5}>
          <div className="text-center mb-4">
            <svg
              className="mb-3" width="40" height="40" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
            </svg>
            <h2 className="h3">Update Product Category</h2>
          </div>

          <Card>
            <Card.Body>
              <Form onSubmit={handleOnSubmit}>
                <Form.Group controlId="categoryName" className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleOnChange}
                    placeholder="Enter category name"
                  />
                </Form.Group>

                <div className="d-grid">
                  {loading ? (
                    <LoadingComponent />
                  ) : (
                    <Button type="submit" variant="primary">
                      Update Category
                    </Button>
                  )}
                </div>
              </Form>

              <hr className="my-4" />
              <div className="d-flex justify-content-between">
                <Link to="/admin/add-brand" className="btn btn-outline-secondary w-100 mx-1">
                  Add Brand
                </Link>
                <Link to="/admin/add-color" className="btn btn-outline-secondary w-100 mx-1">
                  Add Color
                </Link>
                <Link to="/admin/add-category" className="btn btn-outline-secondary w-100 mx-1">
                  Add Category
                </Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
