/*const PdfEmbed = ({ pdfUrl, showAllPages = false }) => {
  return (

    <object data={"https://docs.google.com/gview?embedded=true&url="+pdfUrl} type="application/pdf" width="100%" height="100%">
        <embed src={"https://docs.google.com/gview?embedded=true&url="+pdfUrl} width="100%" height="100%"/> 
    </object>
    
  );
};

export default PdfEmbed;*/
const PdfEmbed = ({ pdfUrl }) => {
  return (
    <iframe
      src={`https://docs.google.com/gview?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
      style={{ width: '100%', height: '100vh', border: 'none' }}
      title="PDF Preview"
    />
  );
};

export default PdfEmbed;
