import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import UserTab from "../../components/user/userTab";
import { PageLayout } from "../../components/page-layout";
import "../../styling/Profile.css";

export const ProfilePage = () => {
  const { user } = useAuth0();

  if (!user) {
    return null;
  }


/** 
 *         <CodeSnippet
                title="Decoded ID Token"
                code={JSON.stringify(user, null, 2)}
              />
 */

  return (
    <PageLayout>
      <div className="profile-layout">
        <h2>Hi! {user.username}</h2>
        <p>Welcome to your user dashboard</p>
      <img src={user.picture} alt="Profile" className="profile-image"/>
      <p></p>

      <UserTab user={user} />
      </div>
    </PageLayout>
  );
};