import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Products = ({ products }) => {
  return (
    <>
    <Row style={{ padding: "1rem", margin:"auto" }}>
      {products?.map((product) => (

        <Col key={product?.id} xs={6} sm={4} md={3} className="mb-3">
        <Link to={{pathname: `/products/${product?.id}`}} style={{color:"black", textDecoration:"none"}}> 
          <div style={{width: "90%", margin:"auto"}}>
          <div className="relative">
            <img style={{width:"100%"}}src={product?.images[0]} alt={product?.name}/>
          </div>

          <div className="p-1">
            <h3 style={{fontSize:"1.2rem"}}>
              {product?.name}
            </h3>
            <p>
              <span>£{product?.price}</span>
            </p>           
          </div>
          </div>
          </Link>
        </Col>

      ))}
    </Row>
    </>
  );
};

export default Products;
