import { Navbar, Container, Nav, NavDropdown, Offcanvas } from 'react-bootstrap';
import { LoginButton } from "../buttons/login-button";
import { LogoutButton } from "../buttons/logout-button";
import { SignupButton } from "../buttons/signup-button";
import { useAuth0 } from "@auth0/auth0-react";
import { FaRegUser, FaShoppingBasket } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getCartItemsFromLocalStorageAction } from '../../redux/slices/cart/cartSlices';
import ShoppingCart from '../../storeComponents/Users/Products/ShoppingCart';
import logo from "../../static/logo512.png"

export default function NavbarJS() {

  const { user } = useAuth0();
  const { isAuthenticated } = useAuth0();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const trackingId = queryParams.get('tId');
    var trackAnon = localStorage.getItem("trackAnon", trackingId)
    if (trackingId && !trackAnon) {
      logAnonTrackingData(trackingId);
      localStorage.setItem("trackAnon", trackingId)
    }
    if(trackAnon && isAuthenticated) {
      AnonCreatedUserFromLink(trackAnon);
      localStorage.removeItem("trackAnon")
    }
  }, [isAuthenticated, location]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCartItemsFromLocalStorageAction());
  }, [dispatch]);
  const { cartItems } = useSelector((state) => state?.carts);

  const AnonCreatedUserFromLink = async (trackingId) => {
    try {
      const formData = new FormData();
      formData.append('trackId', trackingId);
      
      await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/v1/analytics/user/createdfromlink`, formData)
    } catch (error) {
      console.log("Analytics ID is not in use")
    } 
  }
  
  const logAnonTrackingData = async (trackingId) => {
    try {
      const formData = new FormData();
      formData.append('trackId', trackingId);
      formData.append('messageType', 1);
      await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/v1/analytics/create/clickedlink`, formData)
    } catch (error) {
      console.log("Analytics ID is not in use")
    }    
  };
  
  return (
    <>


    <Navbar expand="lg" className="bg-body-tertiary main-nav">
      <Container fluid>
        
        
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Brand className='d-none d-lg-flex' style={{ padding:"0", margin:"0 5px 0 0"}} href="/"><img style={{width:"60px",margin:"0", padding:"5px"}} src={logo} alt="logo" /></Navbar.Brand>{/*SEN Resource*/}
        <Navbar.Brand className='d-flex d-lg-none' style={{ padding:"0", margin:"0 5px 0 40px"}} href="/"><img style={{width:"60px",margin:"0", padding:"5px"}} src={logo} alt="logo" /></Navbar.Brand>{/*SEN Resource*/}
        <div className='d-lg-none'>
        {!isAuthenticated && (
        <>
          <LoginButton />
        </>
      )}
      {isAuthenticated && (
        <div className='d-flex align-items-center'>
        <NavDropdown className="dropdown-menu-end pull-right" style={{maxWidth:"fit-content",padding:"5px", textAlign:"center"}} title={
          <img
            src={user.picture} 
            alt="Profile"
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
            }}
          />
        } id="navbarScrollingDropdownUser" drop="down" align="end">
          
          <span style={{padding:"4px 10px"}}>{user.username}</span>
          <NavDropdown.Divider />
          <NavDropdown.Item href="/profile"><FaRegUser />  Profile</NavDropdown.Item>
          <NavDropdown.Divider />
          <LogoutButton />
        </NavDropdown>
        
        <button onClick={handleShow} className="dropdown-menu-end pull-right" style={{padding:"5px", textAlign:"center", marginRight:"5px",fontSize:"1.5rem", color:"black", border: "none", backgroundColor:"rgba(0,0,0,0)"}}>
          <FaShoppingBasket />
          <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800" style={{"fontSize": "1rem", "textDecoration": "none", "position": "absolute",}}>
            {cartItems?.length > 0 ? cartItems?.length : 0}
          </span>
        </button>
        </div>
        
      )}
        </div>
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
          >
            <Nav.Link href="/topics">Resources</Nav.Link>
   
            <NavDropdown title="Tools" id="navbarScrollingDropdown">
                <NavDropdown.Item href="/tools">All Tools</NavDropdown.Item>
                <NavDropdown.Divider />
              <NavDropdown.Item href="/tool/profile-creator" disabled>SEN Profile Builder (Coming Soon!)</NavDropdown.Item>
              <NavDropdown.Item href="/tool/aqtest">Autism Assessment (AQ)</NavDropdown.Item>
              <NavDropdown.Item href="/tool/asrs">ADHD Assessment (ASRS)</NavDropdown.Item>
              <NavDropdown.Item href="/tool/dst">Dyslexia Assessment (Screening)</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/shop">Shop</Nav.Link> 
            <Nav.Link href="/community" disabled>Community</Nav.Link>
            <Nav.Link href="/blog" disabled>Blog</Nav.Link>         



        </Nav>
        <div className='d-none d-lg-flex'>
        {!isAuthenticated && (
        <>
          <SignupButton />{'\u00A0'}{'\u00A0'}
          <LoginButton />
        </>
      )}
      {isAuthenticated && (
        <div className='d-flex align-items-center'>
        <NavDropdown className="dropdown-menu-end pull-right" style={{maxWidth:"fit-content",padding:"5px", textAlign:"center"}} title={
          <img
            src={user.picture} 
            alt="Profile"
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
            }}
          />
        } id="navbarScrollingDropdownUser" drop="down" align="end">
          
          <span style={{padding:"4px 10px"}}>{user.username}</span>
          <NavDropdown.Divider />
          <NavDropdown.Item href="/profile"><FaRegUser />  Profile</NavDropdown.Item>
          <NavDropdown.Divider />
          <LogoutButton />
        </NavDropdown>
        
        <button onClick={handleShow} className="dropdown-menu-end pull-right" style={{padding:"5px", textAlign:"center", marginRight:"5px",fontSize:"1.5rem", color:"black", border: "none", backgroundColor:"rgba(0,0,0,0)"}}>
          <FaShoppingBasket />
          <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800" style={{"fontSize": "1rem", "textDecoration": "none", "position": "absolute",}}>
            {cartItems?.length > 0 ? cartItems?.length : 0}
          </span>
        </button>
        </div>
      )}</div>

        </Navbar.Collapse>
        
      </Container>
      
    </Navbar>


      <Offcanvas show={show} placement={"end"} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Your Basket</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ShoppingCart />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}


//        </Container><Link href="/basket" style={{padding:"5px", textAlign:"center", marginRight:"5px",fontSize:"1.5rem", color:"black"}}>
//<FaShoppingBasket />
//</Link>

/*
<Navbar expand="lg" className="bg-body-tertiary main-nav">
  <Container fluid>
    <Navbar.Toggle aria-controls="navbarScroll" />

    
    <Navbar.Brand href="/" className="mx-auto">
      <img src={logo} alt="logo" style={{ width: "50px", padding: "5px" }} />
    </Navbar.Brand>

    <div className="d-flex align-items-center ms-auto">
      {isAuthenticated ? (
        <>
          <NavDropdown
            className="me-3"
            title={<img src={user.picture} alt="Profile" style={{ width: "30px", height: "30px", borderRadius: "50%" }} />}
            id="navbarScrollingDropdownUser"
            align="end"
          >
            <span style={{ padding: "4px 10px" }}>{user.username}</span>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/profile"><FaRegUser /> Profile</NavDropdown.Item>
            <NavDropdown.Divider />
            <LogoutButton />
          </NavDropdown>
          
          <button onClick={handleShow} className="me-3" style={{ fontSize: "1.5rem", color: "black", border: "none", backgroundColor: "transparent", position: "relative" }}>
            <FaShoppingBasket />
            <span style={{ fontSize: "1rem", position: "absolute", top: "-5px", right: "-5px", backgroundColor: "red", color: "white", borderRadius: "50%", padding: "2px 6px", fontSize: "0.8rem" }}>
              {cartItems?.length || 0}
            </span>
          </button>
        </>
      ) : (
        <>
          <SignupButton />
          <LoginButton />
        </>
      )}
    </div>

    <Navbar.Collapse id="navbarScroll">
      <Nav className="me-auto">
        <Nav.Link href="/topics">Resources</Nav.Link>
        <NavDropdown title="Tools" id="navbarScrollingDropdown">
          <NavDropdown.Item href="/tools">All Tools</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="/tool/profile-creator" disabled>SEN Profile Builder (Coming Soon!)</NavDropdown.Item>
          <NavDropdown.Item href="/tool/aqtest">Autism Assessment (AQ)</NavDropdown.Item>
          <NavDropdown.Item href="/tool/asrs">ADHD Assessment (ASRS)</NavDropdown.Item>
          <NavDropdown.Item href="/tool/dst">Dyslexia Assessment (Screening)</NavDropdown.Item>
        </NavDropdown>
        <Nav.Link href="/shop">Shop</Nav.Link>
        <Nav.Link href="/community" disabled>Community</Nav.Link>
        <Nav.Link href="/blog" disabled>Blog</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>

*/
