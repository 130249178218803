import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchBrandsAction } from "../../../redux/slices/categories/brandsSlice";
import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import LoadingComponent from "../../LoadingComp/LoadingComponent";
import NoDataFound from "../../NoDataFound/NoDataFound";
import { Container, Row, Col, Table, Button, Card } from "react-bootstrap";

export default function BrandsList() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBrandsAction());
  }, [dispatch]);

  const {
    brands: { brands },
    loading,
    error,
  } = useSelector((state) => state?.brands);

  const deleteCategoryHandler = (id) => {
    // Add delete logic here
  };

  return (
    <Container className="py-4">
      <Row className="align-items-center mb-4">
        <Col>
          <h1 className="h4">All Brands Categories [{brands?.length}]</h1>
          <p className="text-muted">
            A list of all the brand categories including their name and creation date.
          </p>
        </Col>
        <Col className="text-end">
          <Link to="/admin/add-category">
            <Button variant="primary">Add New Category</Button>
          </Link>
        </Col>
      </Row>

      {loading ? (
        <LoadingComponent />
      ) : error ? (
        <ErrorMsg message={error?.message} />
      ) : brands?.length <= 0 ? (
        <NoDataFound />
      ) : (
        <Card>
          <Card.Body>
            <Table responsive bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {brands?.map((brand) => (
                  <tr key={brand?._id}>
                    <td>{brand?.name}</td>
                    <td>{new Date(brand?.createdAt).toLocaleDateString()}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
    </Container>
  );
}
