import React, { useEffect, useState, useCallback } from 'react';
import { Link } from "react-router-dom";
import { Pagination } from "react-bootstrap";
import axios from 'axios';
import LoadingWheel from '../loading';
import "../../styling/Resources.css";
import PdfThumbnail from '../pdf/PdfThumbnail';

export default function DisplayAllResources({ urlPassed, dataPassed }) {
  const [itemsData, setItemsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {

    const getItems = async () => {
      setLoading(true);
      try {
        const endpoint = dataPassed
          ? `${process.env.REACT_APP_API_SERVER_URL}${urlPassed}?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`
          : `${process.env.REACT_APP_API_SERVER_URL}/v1/items?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`;
        const response = await axios.get(endpoint);
        
        setItemsData(response.data);
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Error fetching items:', error);
          setError(true);
        }
      } finally {
        setLoading(false);
      }
    };

    getItems();
  }, [currentPage, dataPassed, itemsPerPage, urlPassed]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  const totalPages = Math.ceil(itemsData.totalItems / itemsPerPage);
  const itemsDisplay = itemsData?.data || [];

  const handleItemsPerPageChange = useCallback((event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  }, []);

  if (loading) return <LoadingWheel />;
  if (!itemsData || itemsData.totalItems === 0 || error) {
    return (
      <>
        <Link className="addResourceBtn" to="/Resources/Create" style={{ marginLeft: "20px" }}>+Resource</Link>
        <h1>Sorry, we cannot find what you are looking for!</h1>
        <p>Please try again later</p>
      </>
    );
  }

  return (
    <>
      <Link className="addResourceBtn" to="/Resources/Create" style={{ marginLeft: "20px" }}>+Resource</Link>

      <div className="itemsList">
        {itemsDisplay.map((item) => (
          <div className="itemsListItem" key={item._id}>
            <Link className="itemsListLink" to={`/resource/${item.slug}`}>
              <div className="itemsListImageContainer">
                {item.images[0]?.file.endsWith('.pdf') ? (
                  <PdfThumbnail pdfUrl={item.images[0].file} />
                ) : (
                  <p>File is not a PDF</p>
                )}
              </div>
              <div className="itemsListContainer">
                {item.targetAge && (
                  <span style={{ fontSize: "0.8rem" }}>
                    {item.targetAge.join(", ")}
                  </span>
                )}
                <p style={{ fontSize: "0.8rem", fontWeight: "bold" }}>By: {item.user}</p>
                <h2>{item.name}</h2>
                <span className="read-more">Read More</span>
              </div>
            </Link>
          </div>
        ))}
      </div>

      <Pagination style={{ margin: "4% auto" }}>
        <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} />
        <Pagination.Item active>
          Page {currentPage} of {totalPages}
        </Pagination.Item>
        <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
      </Pagination>

      <div className='p-4'>
        <label htmlFor="itemsPerPage">Items Per Page:</label>
        <select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
        </select>
      </div>
    </>
  );
}
