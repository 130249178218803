import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { createColorAction } from "../../../redux/slices/categories/colorsSlice";
import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import LoadingComponent from "../../LoadingComp/LoadingComponent";
import SuccessMsg from "../../SuccessMsg/SuccessMsg";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";

export default function AddColor() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ name: "" });

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    dispatch(createColorAction(formData.name));
    setFormData({ name: "" });
  };

  const { error, loading, isAdded } = useSelector((state) => state?.colors);

  return (
    <Container className="py-4">
      {isAdded && <SuccessMsg message="Color Created Successfully" />}
      {error && <ErrorMsg message={error?.message} />}
      
      <Row className="justify-content-center">
        <Col md={8} lg={6}>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">Add Product Color</h2>
              <Form onSubmit={handleOnSubmit}>
                <Form.Group controlId="colorName" className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleOnChange}
                    placeholder="Enter color name"
                  />
                </Form.Group>
                <Button type="submit" variant="primary" className="w-100">
                  {loading ? <LoadingComponent /> : "Add Product Color"}
                </Button>
              </Form>
              <div className="text-center mt-4">
                <p>Or</p>
                <Row className="gx-2">
                  <Col>
                    <Link to="/admin/add-brand">
                      <Button variant="secondary" className="w-100">
                        Add Brand
                      </Button>
                    </Link>
                  </Col>
                  <Col>
                    <Link to="/admin/add-color">
                      <Button variant="secondary" className="w-100">
                        Add Color
                      </Button>
                    </Link>
                  </Col>
                  <Col>
                    <Link to="/admin/add-category">
                      <Button variant="secondary" className="w-100">
                        Add Category
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
