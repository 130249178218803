import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrdersAction } from "../../../redux/slices/orders/ordersSlices";
import { Table, Button, Container, Row, Col } from "react-bootstrap";

const people = [
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  // More people...
];

export default function Customers() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOrdersAction());
  }, [dispatch]);

  const {
    error,
    loading,
    orders: { orders } = {},
  } = useSelector((state) => state?.orders);

  return (
    <Container className="mt-4">
      <Row className="mb-3">
        <Col>
          <h1 className="h4">All Customers</h1>
          <p className="text-muted">
            A list of all the users in your account including their name, title,
            email, and role.
          </p>
        </Col>
        <Col className="text-end">
          <Button variant="primary">Add User</Button>
        </Col>
      </Row>
      <Table striped bordered hover responsive className="align-middle">
        <thead>
          <tr>
            <th>Name</th>
            <th>Title</th>
            <th>Email</th>
            <th>Role</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {people.map((person) => (
            <tr key={person.email}>
              <td>{person.name}</td>
              <td>{person.title}</td>
              <td>{person.email}</td>
              <td>{person.role}</td>
              <td>
                <a href="#" className="text-primary">
                  Edit
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
