import React, { useState, useEffect } from "react";
import "../../styling/popUpForm.css";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import logo from "../../static/SEN-Logo-Clear.png";
import LoadingWheel from "../loading";
import { Form, Button, Row, Col } from "react-bootstrap";

const CreateItem = ({ title }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [itemName, setItemName] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [selectedTargetAges, setSelectedTargetAges] = useState([]);
  const [itemImage, setItemImage] = useState(null);
  const [topicsAvailable, setTopicsAvailable] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [topicsError, setTopicsError] = useState(false);

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/v1/topics/`);
        setTopicsAvailable(response.data);
      } catch (error) {
        setTopicsError(true);
      }
    };

    fetchTopics();
  }, []);

  const validateForm = () => {
    if (selectedTopics.length === 0) return "Please select at least one topic";
    if (selectedTargetAges.length === 0) return "Please select at least one target age";
    if (!itemName.trim()) return "Resource name cannot be empty";
    if (!itemDescription.trim()) return "Resource description cannot be empty";
    if (itemImage && !itemImage.name.toLowerCase().endsWith(".pdf")) return "Please upload PDF files only";
    if (itemImage && itemImage.size > 4 * 1024 * 1024) return "File size must be 4MB or below";
    return "";
  };

  const handleButtonClick = async () => {
    setErrorMessage("");
    
    const error = validateForm();
    if (error) {
      setErrorMessage(error);
      return;
    }

    setIsSubmitting(true);
    
    try {
      const accessToken = await getAccessTokenSilently();
      const formData = new FormData();
      formData.append("itemName", itemName);
      formData.append("itemDescription", itemDescription);
      formData.append("topics", JSON.stringify(selectedTopics));
      formData.append("itemImage", itemImage);
      formData.append("targetAges", JSON.stringify(selectedTargetAges));

      const response = await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/v1/items`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      window.location.href = `https://senresource.com/resource/${response.data.newItem.slug}`;
    } catch (error) {
      setErrorMessage(error.response?.data?.error || "Error submitting form");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTargetAgeChange = (age) => {
    setSelectedTargetAges((prevAges) => 
      prevAges.includes(age) ? prevAges.filter((a) => a !== age) : [...prevAges, age]
    );
  };

  const handleTopicChange = (topic) => {
    setSelectedTopics((prevTopics) => 
      prevTopics.some((t) => t.topic === topic._id)
        ? prevTopics.filter((t) => t.topic !== topic._id)
        : [...prevTopics, { topic: topic._id, topicName: topic.name }]
    );
  };

  if (topicsError) {
    return <p>Could not get topics. Please refresh the page or try again later.</p>;
  }

  return (
    <>
      {!isSubmitting ? (
        <Form style={{ width: "80%", margin: "5% auto" }}>
          <img style={{ width: "120px", margin: "auto" }} src={logo} alt="Logo" />
          <h2 style={{ textAlign: "center" }}>{title} Your Resource</h2>

          <div className="form-group">
            <label htmlFor="itemName">Resource Name:</label>
            <input
              name="itemName"
              type="text"
              id="itemName"
              value={itemName}
              maxLength="50"
              onChange={(e) => setItemName(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="itemDescription">Resource Description:</label>
            <textarea
              name="itemDescription"
              id="itemDescription"
              value={itemDescription}
              style={{ padding: "5px", width: "100%", borderRadius: "8px" }}
              onChange={(e) => setItemDescription(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <Form.Label>Select Topics:</Form.Label>
            <Row>
              {topicsAvailable.map((topic) =>
                topic.name !== "All Resources" && (
                  <Col key={topic._id} md={4} sm={6} xs={12} className="mb-2">
                    <Form.Check
                      type="checkbox"
                      id={topic._id}
                      label={topic.name}
                      checked={selectedTopics.some((t) => t.topic === topic._id)}
                      onChange={() => handleTopicChange(topic)}
                    />
                  </Col>
                )
              )}
            </Row>
          </div>

          <div className="form-group">
            <Form.Label>
              Select Target Age <br />
              <span style={{ fontSize: "0.8rem" }}>This is meant to be a rough guide</span>:
            </Form.Label>
            <Row>
              {["All", "Early years", "Key Stage 1", "Key Stage 2", "Key Stage 3", "Adult"].map((age) => (
                <Col key={age} md={4} sm={6} xs={12} className="mb-2">
                  <Form.Check
                    type="checkbox"
                    id={age}
                    label={age}
                    checked={selectedTargetAges.includes(age)}
                    onChange={() => handleTargetAgeChange(age)}
                  />
                </Col>
              ))}
            </Row>
          </div>

          <div className="form-group">
            <label htmlFor="image1">File (PDF Only):</label>
            <input
              type="file"
              id="image1"
              name="image1"
              accept=".pdf"
              onChange={(e) => setItemImage(e.target.files[0])}
              required
            />
          </div>

          <p style={{ color: "red", textAlign: "center" }}>{errorMessage}</p>
          <Button type="button" className="pull-right" onClick={handleButtonClick} disabled={isSubmitting}>
            Submit
          </Button>
          <p style={{ fontSize: "0.8rem", textAlign: "center" }}>
            By submitting this form you agree to our Privacy Policy and Terms and Conditions
          </p>
        </Form>
      ) : (
        <div style={{ padding: "10px" }}>
          <h1>Uploading your resource</h1>
          <h2>Do not refresh this page</h2>
          <p>Please be patient, this may take a while</p>
          <LoadingWheel />
        </div>
      )}
    </>
  );
};

export default CreateItem;
