import axios from "axios";
import { act } from "react-dom/test-utils";
import { useAuth0 } from "@auth0/auth0-react";
import baseURL from "../../../utils/baseURL";
import {
  resetErrAction,
  resetSuccessAction,
} from "../globalActions/globalActions";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

//initalsState
const initialState = {
  products: [],
  product: {},
  loading: false,
  error: null,
  isAdded: false,
  isUpdated: false,
  isDelete: false,
};

//create product action
export const createDigitalProductAction = createAsyncThunk(
  "digitalProduct/create",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        name,
        description,
        category,
        brand,
        productLocation,
        price,
        files,
      } = payload;

      
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      //FormData
      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
      formData.append("category", category);

      formData.append("brand", brand);
      formData.append("price", price);
      formData.append("location", productLocation);

      files.forEach((file) => {
        formData.append("files", file);
      });

      const { data } = await axios.post(
        `${baseURL}/digitalProducts`,
        formData,
        config
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

//create product action
export const updateDigitalProductAction = createAsyncThunk(
  "digitalProduct/update",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        name,
        description,
        category,
        brand,
        productLocation,
        price,
        id,
      } = payload;

      const { data } = await axios.put(
        `${baseURL}/digitalProducts/${id}`,
        {
          name,
          description,
          category,
          brand,
          price,
          productLocation,
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

//fetch products action
export const fetchDigitalProductsAction = createAsyncThunk(
  "digitalProduct/list",
  async ({ url }, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(`${url}`);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

//fetch product action
export const fetchDigitalProductAction = createAsyncThunk(
  "digitalProduct/details",
  async (productId, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        `${baseURL}/digitalProducts/${productId}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
//slice
const digitalProductSlice = createSlice({
  name: "digitalProducts",
  initialState,
  extraReducers: (builder) => {
    //create
    builder.addCase(createDigitalProductAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createDigitalProductAction.fulfilled, (state, action) => {
      state.loading = false;
      state.product = action.payload;
      state.isAdded = true;
    });
    builder.addCase(createDigitalProductAction.rejected, (state, action) => {
      state.loading = false;
      state.product = null;
      state.isAdded = false;
      state.error = action.payload;
    });
    //update
    builder.addCase(updateDigitalProductAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateDigitalProductAction.fulfilled, (state, action) => {
      state.loading = false;
      state.product = action.payload;
      state.isUpdated = true;
    });
    builder.addCase(updateDigitalProductAction.rejected, (state, action) => {
      state.loading = false;
      state.product = null;
      state.isUpdated = false;
      state.error = action.payload;
    });
    //fetch all
    builder.addCase(fetchDigitalProductsAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDigitalProductsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.products = action.payload;
      state.isAdded = true;
    });
    builder.addCase(fetchDigitalProductsAction.rejected, (state, action) => {
      state.loading = false;
      state.products = null;
      state.isAdded = false;
      state.error = action.payload;
    });
    //fetch all
    builder.addCase(fetchDigitalProductAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDigitalProductAction.fulfilled, (state, action) => {
      state.loading = false;
      state.product = action.payload;
      state.isAdded = true;
    });
    builder.addCase(fetchDigitalProductAction.rejected, (state, action) => {
      state.loading = false;
      state.product = null;
      state.isAdded = false;
      state.error = action.payload;
    });
    //reset error
    builder.addCase(resetErrAction.pending, (state, action) => {
      state.error = null;
    });
    //reset success
    builder.addCase(resetSuccessAction.pending, (state, action) => {
      state.isAdded = false;
    });
  },
});

//generate the reducer
const digitalProductReducer = digitalProductSlice.reducer;

export default digitalProductReducer;
