import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faHandshake, faDownload } from '@fortawesome/free-solid-svg-icons';
import HeroImage from "../components/hero";
import topicsImageHeader from "../static/topicsImage.jpg";
import { PageLayout } from "../components/page-layout";
import logo from "../static/logo512.png"

export default function Landing() {   

return(
  <PageLayout>
    <HeroImage imagepath={topicsImageHeader} heroImageIcon={logo} header="" subheader='"Increasing the standard of SEN support in mainstream education"' pad='4%'></HeroImage>
    <br/>
    <Container id="services" style={{textAlign: "center"}}>
  
        <Row className="justify-content-center mb-5">
          <Col xs={12} sm={6} md={4}>
            <FontAwesomeIcon icon={faDownload} size="3x" className="mb-3" />
            <h3>Downloadable Content</h3>
            <p>Our downloadable content ensures convenient access to valuable resources. Whether it's guides, worksheets, or multimedia materials, everything is just a click away.</p>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <FontAwesomeIcon icon={faHandshake} size="3x" className="mb-3" />
            <h3>Free Resources</h3>
            <p>Enjoy unrestricted access to our extensive collection of resources at no cost. Explore, learn, and thrive with us.</p>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <FontAwesomeIcon icon={faUsers} size="3x" className="mb-3" />
            <h3>Created by you</h3>
            <p>Our resources are curated by SEN teachers, parents, and professionals like you. Thank you for being a part of our community.</p>
          </Col>
        </Row>
      </Container>
      

      <div id="explore" style={{textAlign: "center", backgroundColor: "silver", display:"none"}}>
      <br/>
        <h2>Explore Our Tools</h2>
        <Row className="justify-content-center mb-5">
          <Col xs={12} sm={6} md={4}>
          
          </Col>
          <Col xs={12} sm={6} md={4}>
          
          </Col>
          <Col xs={12} sm={6} md={4}>
          
          </Col>
        </Row>
      </div>


      <div id="explore" style={{textAlign: "center", display:"none"}}>
      <br/>
        <h2>Explore Resources</h2>
        <Row className="justify-content-center mb-5">
          <Col xs={12} sm={6} md={4}>
          
          </Col>
          <Col xs={12} sm={6} md={4}>
          
          </Col>
          <Col xs={12} sm={6} md={4}>
          
          </Col>
        </Row>
      </div>


      <div id="explore" style={{textAlign: "center", backgroundColor: "silver", display:"none"}}>
      <br/>
        <h2>Explore Blog Posts</h2>
        <Row className="justify-content-center mb-5">
          <Col xs={12} sm={6} md={4}>
          
          </Col>
          <Col xs={12} sm={6} md={4}>
          
          </Col>
          <Col xs={12} sm={6} md={4}>
          
          </Col>
        </Row>
      </div>

      
      <Container style={{backgroundColor: "silver"}}>
        <h2>GET IN TOUCH</h2>
        <p>Found an issue or have a resource you would like to add to the site?</p>
        <p><a href="/contact">Get in touch here</a></p>
      </Container>
<br/>

</PageLayout>)
}
    
  
    