import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchBrandsAction } from "../../../redux/slices/categories/brandsSlice";
import { fetchCategoriesAction } from "../../../redux/slices/categories/categoriesSlice";
import { fetchDigitalProductAction, updateDigitalProductAction } from "../../../redux/slices/products/digitalProducts";

import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import LoadingComponent from "../../LoadingComp/LoadingComponent";
import SuccessMsg from "../../SuccessMsg/SuccessMsg";
import { Button, Form, Col, Row, Container } from "react-bootstrap";

export default function DigitalProductUpdate() {
  //dispatch
  const dispatch = useDispatch();
  //get id from params
  const { id } = useParams();
  //fetch single product
  useEffect(() => {
    dispatch(fetchDigitalProductAction(id));
  }, [id, dispatch]);

  //categories
  useEffect(() => {
    dispatch(fetchCategoriesAction());
  }, [dispatch]);
  //select data from store
  const { categories } = useSelector((state) => state?.categories?.categories);

  //brands
  useEffect(() => {
    dispatch(fetchBrandsAction());
  }, [dispatch]);
  //select data from store
  const {
    brands: { brands },
  } = useSelector((state) => state?.brands);

  //get product from store
  const { product, isUpdated, loading, error } = useSelector(
    (state) => state?.digitalProducts
  );

  useEffect(() => {
    if (product) {
      setFormData({
        name: product?.product?.name || "",
        description: product?.product?.description || "",
        category: product?.product?.category || "",
        brand: product?.product?.brand || "",
        price: product?.product?.price || "",
      });
    }
  }, [product]);

  //---form data---
  const [formData, setFormData] = useState({
    name: product?.product?.name,
    description: product?.product?.description,
    category: product?.product?.category,
    brand: product?.product?.brand,
    price: product?.product?.price,
  });

  //onChange
  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //onSubmit
  const handleOnSubmit = (e) => {
    e.preventDefault();
    //dispatch
    dispatch(
      updateDigitalProductAction({
        ...formData,
        id,
      })
    );

    //reset form data
    setFormData({
      name: "",
      description: "",
      category: "",
      brand: "",
      images: "",
      price: "",
    });
  };

  if (loading) {
    return <LoadingComponent />;
  }  

  return (
    <Container className="py-5">
      {error && <ErrorMsg message={error?.message} />}
      {isUpdated && <SuccessMsg message="Product Updated Successfully" />}

      <Row className="justify-content-center">
        <Col xs={12} sm={8} md={6}>
          <h2 className="text-center">Update Product</h2>
          <p className="text-center text-muted">
            <strong>Manage Products</strong>
          </p>
          <div className="bg-white shadow rounded p-4">
            <Form onSubmit={handleOnSubmit}>
              <Form.Group controlId="productName">
                <Form.Label>Product Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData?.name}
                  onChange={handleOnChange}
                  required
                />
              </Form.Group>


              {/* Select category */}
              <Form.Group controlId="selectCategory">
                <Form.Label>Select Category</Form.Label>
                <Form.Control
                  as="select"
                  name="category"
                  value={formData.category}
                  onChange={handleOnChange}
                >
                  <option>-- Select Category --</option>
                  {categories?.map((category) => (
                    <option key={category?._id} value={category?.name}>
                      {category.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              {/* Select Brand */}
              <Form.Group controlId="selectBrand">
                <Form.Label>Select Brand</Form.Label>
                <Form.Control
                  as="select"
                  name="brand"
                  value={formData.brand}
                  onChange={handleOnChange}
                >
                  <option>-- Select Brand --</option>
                  {brands?.map((brand) => (
                    <option key={brand?._id} value={brand?.name}>
                      {brand.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              {/* Price */}
              <Form.Group controlId="price">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  name="price"
                  value={formData.price}
                  onChange={handleOnChange}
                  required
                />
              </Form.Group>

              {/* Description */}
              <Form.Group controlId="description">
                <Form.Label>Add Product Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="description"
                  value={formData.description}
                  onChange={handleOnChange}
                />
              </Form.Group>

              {/* Submit Button */}
              <Button
                variant="primary"
                type="submit"
                className="w-100"
                disabled={loading}
              >
                {loading ? "Updating..." : "Update Product"}
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
