import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Form, FormGroup, FormLabel, FormSelect, Container } from "react-bootstrap";
import { updateOrderAction } from "../../../redux/slices/orders/ordersSlices";

const UpdateOrders = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState({
    status: "pending",
  });

  const onChange = (e) => {
    dispatch(updateOrderAction({ status: e.target.value, id }));
    window.location.href = "/admin";
  };

  return (
    <Container className="mt-4">
      <FormGroup>
        <FormLabel>Update Order</FormLabel>
        <FormSelect
          name="status"
          onChange={onChange}
          value={order.status}
        >
          <option value="pending">Pending</option>
          <option value="processing">Processing</option>
          <option value="shipped">Shipped</option>
          <option value="delivered">Delivered</option>
          <option value="cancelled">Cancelled</option>
          <option value="returned">Returned</option>
          <option value="refunded">Refunded</option>
          <option value="refundedPartial">RefundedPartial</option>
        </FormSelect>
      </FormGroup>
    </Container>
  );
};

export default UpdateOrders;
