import React, { useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PageLayout } from "../../../components/page-layout";

export default function ThanksForOrdering() {

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify([]))
  })

  return (
    <main className="bg-light">
      <PageLayout>
      <div className="container">
        <Row className="g-0">
        <Col lg={6}>
        
        </Col>
          {/* Image Section */}
          <Col lg={6}>
            <div className="p-5">
              <h1 className="text-primary mb-3">Payment Successful</h1>
              <h2 className="fw-bold mb-3">
                Thanks for ordering!
              </h2>
              <p className="text-muted mb-4">
                We appreciate your order. We're currently processing it, so hang
                tight! We'll send you a confirmation very soon.
              </p>
              <hr></hr>
              <span style={{fontSize:"0.8rem"}}>Didnt get an email? Please make sure to check your spam folder. Emails can also take a while to be sent out. If you still havent recieved your email please get in touch using the contact us form.</span>
              <div className="text-end p-2">
                <Link to="/">
                  <Button variant="primary">
                    Continue Shopping <span aria-hidden="true">→</span>
                  </Button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        </div>
        </PageLayout>
        
    </main>
  );
}
