//import axios to make API calls
import axios from 'axios';
//import React and useEffect and useState modules from react
import React, { useEffect, useState} from 'react';
//import the pop up form component
import PopupForm from '../../components/forms/popUpFormTopic';
//import the display all topics component
//import the page styling
import "../../styling/Topics.css"
import LoadingWheel from '../../components/loading';
import { PageLayout } from "../../components/page-layout";

function TopicsAdd() {
  //get and set for the topics in the database
  const [topicsData, setTopicsData] = useState([]);
//get and set for the loading content screen
  const [loading, setLoading] = useState(false)
  //get and set for the pop up form component
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  //handle submit for the form
  const handleSubmit = (formData) => {
    //log that the form is submitted
    console.log('Form submitted with:', formData);
  };
  

  useEffect(() => {
    //set loading to true to display the loading screen
    setLoading(true)
    //call the API to get the users topics
    axios.get(`http://${process.env.REACT_APP_API_SERVER_URL}/v1/topics/`)
    .then(response => {
      //set itemsData with the response
      const topics = response.data;
      setTopicsData(topics)
    })
    //catch if the call fails
    .catch(error => {
              //log any errors
      console.error('Error fetching topic:', error);
      
    })
          //once the call is complete
    .finally(() => {
              //set loading to false
        setLoading(false)
    });
  }, []);

//if the page is loading
  if(loading) {
        //display loading message
    return (<>
    <LoadingWheel />
    </>)
}
//if the page isnt loading 
else {

//if there are no topics
if (!topicsData) {
    //display a 404 error
  return (
    <>
      <h1>USE 404 HERE</h1>
    </>
  );
}
//return the HTML output
  return (
    <PageLayout>
        
      
            
                <button className='btn' onClick={() => setIsPopupOpen(true)}>+ Topic</button>
         


            <PopupForm isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} onSubmit={handleSubmit} />
            
            

            </PageLayout>

  );
}
}
//export Topics
export default TopicsAdd;

