import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

export const useAuthInterceptor = () => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    console.log("hi")
    if (isLoading || !isAuthenticated) return; // If not authenticated, don't attach the interceptor.

    let requestInterceptor, responseInterceptor;

    // Attach interceptors only if the user is authenticated
    const attachInterceptors = async () => {
      requestInterceptor = axios.interceptors.request.use(
        async (config) => {
          if (!isAuthenticated) return config; // Let the request proceed without modification.

          try {
            const token = await getAccessTokenSilently();
            config.headers["Authorization"] = `Bearer ${token}`;
          } catch (error) {
            console.error("Failed to fetch token:", error);
          }
          return config;
        },
        (error) => Promise.reject(error)
      );

      responseInterceptor = axios.interceptors.response.use(
        (response) => response,
        async (error) => {
          const originalRequest = error.config;

          if (isAuthenticated && error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
              const newToken = await getAccessTokenSilently({ ignoreCache: true });
              originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
              return axios(originalRequest);
            } catch (refreshError) {
              console.error("Token refresh failed:", refreshError);
              return Promise.reject(refreshError);
            }
          }

          return Promise.reject(error);
        }
      );
    };
console.log("hello")
    attachInterceptors();

    // Cleanup: Remove interceptors when the component unmounts.
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [getAccessTokenSilently, isAuthenticated, isLoading]);
};
