import React, { useEffect, useState } from 'react';
import { FaFilePdf } from 'react-icons/fa';
import * as pdfjsLib from 'pdfjs-dist';

//pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
pdfjsLib.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjsLib.version}/build/pdf.worker.min.mjs`;
const PdfThumbnail = ({ pdfUrl, showAllPages = false}) => {
  const [thumbnails, setThumbnails] = useState([]);
  const [canvasKey, setCanvasKey] = useState(Date.now());

  useEffect(() => {
    const renderPdf = async () => {
      if (!pdfUrl) return;

      try {
        const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
        const numPages = pdf.numPages;

        const renderedPages = [];
        const renderPage = async (pageNum) => {
          const page = await pdf.getPage(pageNum);
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          const viewport = page.getViewport({ scale: 1.0 });
          canvas.width = viewport.width;
          canvas.height = viewport.height;

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          const renderTask = page.render(renderContext);
          await renderTask.promise;

          return new Promise((resolve) => {
            canvas.toBlob((blob) => {
              const url = URL.createObjectURL(blob);
              resolve(url);
            }, 'image/png');
          });
        };

        if (showAllPages) {
          for (let i = 1; i <= numPages; i++) {
            const url = await renderPage(i);
            renderedPages.push(url);
          }
        } else {
          const url = await renderPage(1); // Render only the first page if `showAllPages` is false
          renderedPages.push(url);
        }

        setThumbnails(renderedPages);
        setCanvasKey(Date.now()); // Set a unique key for each render

      } catch (error) {
        console.error('Error rendering PDF:', error);
      }
    };

    renderPdf();

    return () => {
      thumbnails.forEach((thumbnail) => {
        URL.revokeObjectURL(thumbnail); // Revoke URLs to free up memory
      });
    };
  }, [pdfUrl, showAllPages]);

  return (
    <div className="pdf-thumbnails">
      {thumbnails.length > 0 ? 
      
      (
        thumbnails.map((thumbnail, index) => (
          <img
            key={`${canvasKey}-${index}`}
            src={thumbnail}
            alt={`PDF thumbnail ${index + 1}`}
            className='pdfImage'
            style={{
              width: '100%',
              objectFit: 'contain',
              marginBottom: '10px', // Spacing between thumbnails
            }}
          />
        ))
      ) :
      (
        <div style={{textAlign: "center"}}>
          <FaFilePdf style={{margin: "auto", marginTop:"10%", fontSize:"3em"}}/>
          <p>Downloadable</p>
        </div>
      )
      
      }
    </div>
  );
};

export default PdfThumbnail;
