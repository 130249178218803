import React from "react";
import { Button, Card, Col, Container, Image, ListGroup, Row, Spinner } from "react-bootstrap";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { CheckCircleFill } from "react-bootstrap-icons";
import UpdateOrders from "./update";

export default function ManageOrders() {
  // Placeholder variables
  let loading, error, allOrders;

  return (
    <div className="bg-light">
      <main className="py-5">
        <Container>
          <Row className="mb-4">
            <Col md={{ span: 8, offset: 2 }}>
              <h1 className="display-4">Manage Orders</h1>
              <p className="text-muted">
                Check the status of recent orders, manage returns, and discover similar products.
              </p>
            </Col>
          </Row>

          <section aria-labelledby="recent-heading">
            {loading ? (
              <h2>Loading...</h2>
            ) : error ? (
              <h2>{error}</h2>
            ) : (
              allOrders?.map((order) => (
                <Card className="mb-4" key={order.number}>
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    <Row className="w-100">
                      <Col sm={6} md={8} lg={9}>
                        <dl className="mb-0">
                          <dt className="fw-bold">Order number</dt>
                          <dd>{order?.orderNumber}</dd>

                          <dt className="fw-bold">Date placed</dt>
                          <dd>
                            <time dateTime={order.createdDatetime}>
                              {new Date(order.createdAt).toLocaleDateString()}
                            </time>
                          </dd>

                          <dt className="fw-bold">Total amount</dt>
                          <dd>${order.totalPrice}</dd>

                          <dt className="fw-bold">Payment Method</dt>
                          <dd>{order?.paymentMethod}</dd>
                        </dl>
                      </Col>
                      <Col sm={6} md={4} lg={3} className="text-end">
                        <Button variant="light">
                          <EllipsisVerticalIcon size={24} />
                        </Button>
                      </Col>
                    </Row>
                  </Card.Header>

                  <ListGroup variant="flush">
                    {order?.orderItems?.map((product) => (
                      <ListGroup.Item key={product?.id}>
                        <Row>
                          <Col xs={4} sm={3} lg={2} className="mb-3 mb-sm-0">
                            <Image
                              src={product?.imageSrc}
                              alt={product?.imageAlt}
                              rounded
                              fluid
                            />
                          </Col>
                          <Col>
                            <div className="d-flex justify-content-between">
                              <h5>{product?.name}</h5>
                              <p className="fw-bold">${product?.discountedPrice}</p>
                            </div>
                            <p className="text-muted">{product?.description}</p>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>

                  <Card.Footer>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex align-items-center">
                          <CheckCircleFill className="text-success me-2" />
                          <span>Status: {order.status}</span>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                          <svg
                            className="bi bi-circle text-danger me-2"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16">
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                          </svg>
                          <span>Payment Status: {order.paymentStatus}</span>
                        </div>
                      </Col>
                      <Col className="text-end">
                        <UpdateOrders id={order?._id} />
                      </Col>
                    </Row>
                  </Card.Footer>
                </Card>
              ))
            )}
          </section>
        </Container>
      </main>
    </div>
  );
}
