import React, { useState } from 'react';
import JSZip from 'jszip';
import axios from 'axios';
import { BsDownload } from 'react-icons/bs';
import { Button } from 'react-bootstrap';
import "./forms.css"

const ImageDownloader = ({ imageData, itemId }) => {

const [isDownloading, setIsDownloading] = useState(false);
const [errorMessage, setErrorMessage] = useState('');
  const incrementDownloads = () => {
    axios.post(`${process.env.REACT_APP_API_SERVER_URL}/v1/items/increment/`+itemId.id);
  }

  const downloadZip = async () => {
    setIsDownloading(true)
    const zip = new JSZip();
    incrementDownloads();
    // Fetch each file and add it to the ZIP
    for (const file of imageData.images) {
      try {
        const response = await fetch(file);
        const blob = await response.blob();
        var thisFileName = file.split("/");
        const length = thisFileName.length;
        thisFileName = thisFileName[length - 1] 
        zip.file(thisFileName, blob);
      } catch (error) {
        console.error('Error fetching file:', error);
      }
    }

    // Generate the ZIP file and trigger download
    try {
      const content = await zip.generateAsync({ type: 'blob' });
      const downloadUrl = URL.createObjectURL(content);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = imageData.fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(downloadUrl); // Clean up the URL object
    } catch (error) {
      setIsDownloading(false)
      setErrorMessage("Error downloading image")
    }
  };
  return (
    <>
    {isDownloading ? (
      <p style={{textAlign:"center"}}>Please wait while your image downloads. If this fails please refresh and try again.</p>
    ):(
      <>
      <p>{errorMessage}</p>
    <Button onClick={downloadZip} className='downloadBtn'>
      Download <BsDownload />
    </Button>
      </>
    
    )}
    </>
    
  );
};

export default ImageDownloader;
