import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Image, ListGroup, Row, Spinner } from "react-bootstrap";
import { getCartItemsFromLocalStorageAction } from "../../../redux/slices/cart/cartSlices";
import { placeOrderAction } from "../../../redux/slices/orders/ordersSlices";
import { getUserShippingAddressAction } from "../../../redux/slices/users/usersSlice";
import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import { PageLayout } from "../../../components/page-layout";
import { Form } from "react-bootstrap";

export default function OrderPayment() {
  // Dispatch and selectors
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCartItemsFromLocalStorageAction());
    dispatch(getUserShippingAddressAction());
  }, [dispatch]);

  const { cartItems } = useSelector((state) => state?.carts);
  const sumTotalPrice = cartItems?.reduce((acc, current) => acc + current?.totalPrice, 0);
  const { loading, error, shippingAddress } = useSelector((state) => state?.users);

  const [formError, setFormError] = useState("")
  const [shippingCost, setShippingCost] = useState(0)
  const [shippingType, setShippingType] = useState("standard")
  const shippingTypes = {
    "free": 0,
    "standard": 4
  }
  const { loading: orderLoading, error: orderErr } = useSelector(
    (state) => state?.orders
  );

  useEffect(() => {
    var physcialProducts = []
    var digitalProducts = [] 

    cartItems.forEach(element => {
      if (element.isDigital) {
        digitalProducts.push(element)
        return;
      } 
      physcialProducts.push(element)
    })

    if (physcialProducts.length <= 0) {
      setShippingCost(0)
      setShippingType("Free")
    } else {
      setShippingCost(shippingTypes.standard)
      setShippingType("Standard")
    }
  }, [cartItems, shippingTypes.standard])

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    country: "",
    region: "",
    postalCode: "",
    phone: "",
    saveAddress: true
  });

  useEffect(() => {
    if (shippingAddress?.status) {
      const addressData = shippingAddress?.data;
      setFormData({
        firstName: addressData.firstName || "",
        lastName: addressData.lastName || "",
        address: addressData.address || "",
        city: addressData.city || "",
        country: addressData.country || "UK",
        region: addressData.region || "",
        postalCode: addressData.postalCode || "",
        phone: addressData.phone || "",
        saveAddress: addressData.saveAddress || true,
      });
    }
  }, [shippingAddress]);

  const onChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const isFormValid = () => {
    const requiredFields = { ...formData };
    delete requiredFields.saveAddress;

    return Object.values(requiredFields).every(value => {
      return value.trim() !== '';
    })
  }
  
  // Place order handler
  const placeOrderHandler = () => {
    if(!isFormValid()) {
      setFormError("Please complete the form")
      return;
    }
    dispatch(
      placeOrderAction({
        shippingAddress: formData,
        orderItems: cartItems,
        shipping: {"type":shippingType},
        totalPrice: sumTotalPrice,
      })
    );
  };

  return (
    <PageLayout>
      <div className="container">
        <h1>Checkout</h1>
      {error && <ErrorMsg message={error?.message} />}
      {orderErr && <ErrorMsg message={orderErr?.message} />}
      <Row>
        <Col lg={6} className="mb-4" style={{padding:"3%", borderRadius:"8px", backgroundColor:"white"}}>
        <>
      {error && <ErrorMsg message={error?.message} />}
      <h3>Shipping details</h3>
      
        <Form className="mt-4">
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={onChange}
                  placeholder="Enter first name"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={onChange}
                  placeholder="Enter last name"
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3" controlId="address">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              name="address"
              value={formData.address}
              onChange={onChange}
              placeholder="Enter your address"
            />
          </Form.Group>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={onChange}
                  placeholder="Enter your city"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="country">
                <Form.Label>Country</Form.Label>
                <Form.Select
                  name="country"
                  value={formData.country}
                  onChange={onChange}
                  defaultValue={"UK"}
                >
                  <option value="UK">United Kingdom</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="region">
                <Form.Label>Region</Form.Label>
                <Form.Control
                  type="text"
                  name="region"
                  value={formData.region}
                  onChange={onChange}
                  placeholder="Enter Region"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="postalCode">
                <Form.Label>Postal Code</Form.Label>
                <Form.Control
                  type="text"
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={onChange}
                  placeholder="Enter postal code"
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3" controlId="phone">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              value={formData.phone}
              onChange={onChange}
              placeholder="Enter your phone number"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="saveAddress">
            <Form.Check
              type="checkbox"
              name="saveAddress"
              checked={formData.saveAddress}
              onChange={onChange}
              label="Save this shipping address for future use"
            />
          </Form.Group>
        </Form>
    
    </>
        </Col>

        <Col lg={6} style={{padding:"5%"}}>
              <h4 className="mb-4">Order Summary</h4>
              <ListGroup variant="flush">
                {cartItems?.map((product) => (
                  <ListGroup.Item key={product._id} className="d-flex align-items-center">
                    <Image
                      src={product.image}
                      alt={product.name}
                      rounded
                      className="me-3"
                      style={{ width: "70px", height: "70px", objectFit: "cover" }}
                    />
                    <div className="flex-grow-1">
                      <h6 className="mb-1">{product.name}</h6>
                      {!product.isDigital && (<><small>
                        {product.size}, {product.color}
                      </small>
                      <p className="mb-0 mt-2">
                        £{product.price} x {product.qty} = £{product.totalPrice}
                      </p></>)}
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>

              <ListGroup variant="flush" className="mt-4">
                <ListGroup.Item className="d-flex justify-content-between">
                  <span>Shipping: {shippingType}</span>
                  <strong>£{shippingCost}</strong>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between">
                  <span>Subtotal</span>
                  <strong>£{sumTotalPrice + shippingCost}</strong>
                </ListGroup.Item>
              </ListGroup>

              <div className="text-center mt-4">
                <p style={{color:"red"}}>{formError}</p>
                {orderLoading ? (
                  <Spinner animation="border" />
                ) : (
                  <Button
                    onClick={placeOrderHandler}
                    variant="primary"
                    className="w-100"
                    disabled={!isFormValid}>
                    Confirm Payment - £{sumTotalPrice + shippingCost}
                  </Button>
                )}
              </div>
            
        </Col>
      </Row>
      </div>
      </PageLayout>
  );
}
