import React, { useState, Suspense } from 'react';
import axios from 'axios';

const CookieBanner = React.lazy(() => import('../CookieBanner'));

const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

const FooterLinkSection = ({ title, links }) => (
  <div className="col-6 col-md-2 mb-3">
    <h5>{title}</h5>
    <ul className="nav flex-column">
      {links.map(({ text, href }) => (
        <li key={text} className="nav-item mb-2">
          <a href={href} className="nav-link p-0 text-muted">{text}</a>
        </li>
      ))}
    </ul>
  </div>
);

const Footer = () => {
  const [emailInput, setEmailInput] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState({ message: '', type: null });
  const [subSubmitting, setSubSubmitting] = useState(false);

  const submitEmailList = async (e) => {
    e.preventDefault();
    if (!validateEmail(emailInput.trim())) {
      setSubscriptionStatus({ message: "Please enter a valid email", type: 'error' });
      return;
    }

    setSubSubmitting(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/v1/users/email/subscribe`, { email: emailInput });

      setSubscriptionStatus({ message: "You have been added to the mail list!", type: 'success' });
      setEmailInput("");
    } catch {
      setSubscriptionStatus({ message: "Unable to subscribe to the email list", type: 'error' });
    }
    setSubSubmitting(false);
  };

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <CookieBanner />
      </Suspense>
      
      <footer className="p-5" style={{ backgroundColor: "aliceblue" }}>
        <div className="row">
          <FooterLinkSection title="Quick Links" links={[
            { text: "Homepage", href: "/" },
            { text: "All Resources", href: "/Items" },
            { text: "All Categories", href: "/Topics" },
            { text: "About Us", href: "/About" }
          ]} />
          
          <FooterLinkSection title="Additional Info" links={[
            { text: "Privacy Policy", href: "/Privacy" },
            { text: "Terms and Conditions", href: "/Terms" },
            { text: "Contact Us", href: "/Contact" }
          ]} />

          <div className="col-md-6 offset-md-2 mb-3">
            <form id="subscription-form" onSubmit={submitEmailList}>
              <h5>Subscribe to our newsletter</h5>
              <p>Monthly digest of what's new and exciting from us.</p>
              <span style={{ color: subscriptionStatus.type === 'success' ? 'green' : 'red' }}>
                {subscriptionStatus.message}
              </span>
              <div className="d-flex flex-column flex-m-row w-100 gap-2">       
                <input type="email" name="email" id="id_email" placeholder="Your Email" value={emailInput} onChange={(e) => setEmailInput(e.target.value)} required/>
                <button id="subscribe-button" className="btn btn-primary" type="submit" disabled={subSubmitting}>Subscribe</button>
              </div>
            </form>
          </div>
        </div>

        {/* Social Media Links */}
        <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
          <ul style={{ margin: "auto", fontSize: "1.2rem" }} className="list-unstyled d-flex">
            <li className="ms-2">
              <a href="https://www.instagram.com/senresource" aria-label="Visit us on Instagram">
                <span className="fa fa-instagram"></span>
              </a>
            </li>
          </ul>
        </div>

        <p style={{ textAlign: "center" }}>
          &copy; {new Date().getFullYear()} SENResource.com by ... All Rights Reserved.
        </p>
      </footer>
    </>
  );
};

export default Footer;
