import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchCategoriesAction } from "../../../redux/slices/categories/categoriesSlice";

import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import LoadingComponent from "../../LoadingComp/LoadingComponent";
import NoDataFound from "../../NoDataFound/NoDataFound";
import { Container, Row, Col, Table, Button, Card, Alert } from "react-bootstrap";

export default function ManageCategories() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCategoriesAction());
  }, [dispatch]);
  const {
    categories: { categories },
    loading,
    error,
  } = useSelector((state) => state?.categories);

  const deleteCategoryHandler = (id) => {};

  return (
    <Container className="py-4">
      <Row className="align-items-center mb-4">
        <Col>
          <h1 className="h4">All Categories</h1>
          <p className="text-muted">
            A list of all the users in your account including their name and title.
          </p>
        </Col>
        <Col className="text-end">
          <Link to="/admin/add-category">
            <Button variant="primary">Add New Category</Button>
          </Link>
        </Col>
      </Row>

      {loading ? (
        <LoadingComponent />
      ) : error ? (
        <ErrorMsg message={error?.message} />
      ) : categories?.length <= 0 ? (
        <NoDataFound />
      ) : (
        <Card>
          <Card.Body>
            <Table responsive bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>No. Products</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {categories?.map((category) => (
                  <tr key={category?._id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <img
                          src={category?.image}
                          alt={category?.name}
                          className="rounded-circle me-3"
                          style={{ width: "40px", height: "40px" }}
                        />
                        <span>{category?.name}</span>
                      </div>
                    </td>
                    <td>{category?.products?.length}</td>
                    <td>{new Date(category?.createdAt).toLocaleDateString()}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
    </Container>
  );
}
