import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { createCategoryAction } from "../../../redux/slices/categories/categoriesSlice";
import ErrorComponent from "../../ErrorMsg/ErrorMsg";
import SuccessMsg from "../../SuccessMsg/SuccessMsg";
import LoadingComponent from "../../LoadingComp/LoadingComponent";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";

export default function CategoryToAdd() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ name: "" });
  const [file, setFile] = useState(null);
  const [fileErr, setFileErr] = useState(null);

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const fileHandleChange = (event) => {
    const newFile = event.target.files[0];
    if (newFile?.size > 1000000) {
      setFileErr(`${newFile?.name} is too large`);
    }
    if (!newFile?.type?.startsWith("image/")) {
      setFileErr(`${newFile?.name} is not an image`);
    }
    setFile(newFile);
  };

  const { loading, error, isAdded } = useSelector((state) => state?.categories);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    dispatch(createCategoryAction({ name: formData?.name, file }));
  };

  return (
    <Container className="py-4">
      {error && <ErrorComponent message={error?.message} />}
      {fileErr && <ErrorComponent message={fileErr} />}
      {isAdded && <SuccessMsg message="Category added successfully" />}

      <Row className="justify-content-center">
        <Col md={8} lg={6}>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">Add Product Category</h2>
              <Form onSubmit={handleOnSubmit}>
                <Form.Group controlId="categoryName" className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleOnChange}
                    placeholder="Enter category name"
                  />
                </Form.Group>
                <Form.Group controlId="categoryFile" className="mb-3">
                  <Form.Label>Upload Image</Form.Label>
                  <Form.Control type="file" onChange={fileHandleChange} />
                  <Form.Text className="text-muted">
                    PNG, JPG, GIF up to 1MB
                  </Form.Text>
                </Form.Group>
                <Button type="submit" variant="primary" className="w-100">
                  {loading ? <LoadingComponent /> : "Add Category"}
                </Button>
              </Form>
              <div className="text-center mt-4">
                <p>Or</p>
                <Row className="gx-2">
                  <Col>
                    <Link to="/admin/add-brand">
                      <Button variant="secondary" className="w-100">
                        Add Brand
                      </Button>
                    </Link>
                  </Col>
                  <Col>
                    <Link to="/admin/add-color">
                      <Button variant="secondary" className="w-100">
                        Add Color
                      </Button>
                    </Link>
                  </Col>
                  <Col>
                    <Link to="/admin/add-category">
                      <Button variant="secondary" className="w-100">
                        Add Category
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
