import { useAuth0 } from "@auth0/auth0-react";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/auth/authentication-guard";
import './styling/main.css';
import { Route, Routes } from "react-router-dom";
import NotFoundPage from "./pages/error/404";
import Resources from "./pages/content/Resources";
import Topics from './pages/content/Topics';
import ViewResource from './pages/content/ViewResource';
import ViewTopic from './pages/content/ViewTopic';
import Landing from './pages/Landing';
import TopicsAdd from './pages/admin/TopicsAdd';
import ResourcesAdd from './pages/admin/ResourcesAdd';
import Contact from './pages/Contact';
import About from './pages/About';
import ComingSoonPage from './pages/error/ComingSoon'
import Privacy from './pages/user/Privacy';
import ProfileBuilder from "./pages/tools/ProfileBuilder";
import Tools from "./pages/tools/Tools";
import Terms from './pages/user/Terms';
import { ProfilePage } from "./pages/user/Profile"  
import AQTest from "./pages/tools/AQ";
import ADHDTest from "./pages/tools/ASRS";
import DyslexiaTest from "./pages/tools/DyslexiaScreening";
import '@fontsource/montserrat';
import Product from "./storeComponents/Users/Products/Product";
import ShoppingCart from "./storeComponents/Users/Products/ShoppingCart";
import ProductsFilters from "./storeComponents/Users/Products/ProductsFilters";
import ThanksForOrdering from "./storeComponents/Users/Products/ThanksForOrdering";
import OrderPayment from "./storeComponents/Users/Products/OrderPayment";
import { useAuthInterceptor } from "./redux/helpers/authInterceptor";
import AddReview from "./storeComponents/Users/Reviews/AddReview";
import OrdersList from "./storeComponents/storeAdmin/orders/orders";
import AddProduct from "./storeComponents/storeAdmin/products/create";
import ManageStocks from "./storeComponents/storeAdmin/products/manage";
import ProductUpdate from "./storeComponents/storeAdmin/products/update";
import AddCoupon from "./storeComponents/storeAdmin/coupons/create";
import ManageCoupons from "./storeComponents/storeAdmin/coupons/manage";
import UpdateCoupon from "./storeComponents/storeAdmin/coupons/update";
import ManageOrders from "./storeComponents/storeAdmin/orders/manage";
import UpdateOrders from "./storeComponents/storeAdmin/orders/update";
import Customers from "./storeComponents/storeAdmin/customers/manage";
import AddminDashboard from "./storeComponents/storeAdmin/dashboard";
import CategoryToAdd from "./storeComponents/storeAdmin/categories/createCategory";
import ManageCategories from "./storeComponents/storeAdmin/categories/manage";
import UpdateCategory from "./storeComponents/storeAdmin/categories/update";
import AddBrand from "./storeComponents/storeAdmin/categories/createBrand";
import BrandsList from "./storeComponents/storeAdmin/categories/brands";
import AddColor from "./storeComponents/storeAdmin/categories/createColour";
import ColorsList from "./storeComponents/storeAdmin/categories/colours";
import { AdminGuard } from "./components/auth/admin-guard";
import AddDigitalProduct from "./storeComponents/storeAdmin/digitalProducts/create";
import ManageDigitalStocks from "./storeComponents/storeAdmin/digitalProducts/manage";
import DigitalProductUpdate from "./storeComponents/storeAdmin/digitalProducts/update";

function App() {
  useAuthInterceptor();

  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }
  return (
        <Routes>
            <Route exact path="/" element={<Landing />} />
            <Route exact path="/Topics" element={<Topics />} />
            <Route exact path="/Resources" element={<Resources />} />
            <Route path="/resource/:slug" element={<ViewResource />} />
            <Route path="/topic/:slug" element={<ViewTopic />} />
          
            <Route exact path="/Resources/Create" element={<AuthenticationGuard component={ResourcesAdd} />} />
            <Route exact path="/Contact" element={<Contact />} />
            <Route exact path="/About" element={<About />} />
            <Route exact path="/Privacy" element={<Privacy />} />
            <Route exact path="/Terms" element={<Terms />} />
            

            <Route exact path="/tools" element={<Tools />} />
            <Route exact path="/tool/profile-creator" element={<AuthenticationGuard component={ProfileBuilder} />} />
            <Route exact path="/tool/aqtest" element={<AQTest />} />
            <Route exact path="/tool/asrs" element={<ADHDTest />} />
            <Route exact path="/tool/dst" element={<DyslexiaTest />} />

            <Route exact path="/blog" element={<ComingSoonPage />} />
            <Route exact path="/community" element={<ComingSoonPage />} />

            <Route
              path="/profile"
              element={<AuthenticationGuard component={ProfilePage} />}
            />


            {/** Store Routes */}

            {/*<Route exact path="/basket" element={<ShoppingCart />} />
            <Route exact path="/shop" element={<ProductsFilters />} />
            <Route path="/products/:id" element={<Product />} />
            <Route path="/success" element={<ThanksForOrdering />} />
            <Route path="/order-payment" element={<AuthenticationGuard component={OrderPayment} />}/>
            <Route path="/add-review/:id" element={<AuthenticationGuard component={AddReview} />}/>*/}
       
            <Route exact path="/basket" element={<AdminGuard component={ShoppingCart} />} />
            <Route exact path="/shop" element={<AdminGuard component={ProductsFilters} />} />
            <Route path="/products/:id" element={<AdminGuard component={Product} /> } />
            <Route path="/success" element={<AdminGuard component={ThanksForOrdering} />} />
            <Route path="/order-payment" element={<AdminGuard component={OrderPayment} />}/>
            <Route path="/add-review/:id" element={<AdminGuard component={AddReview} />}/>

          <Route path="admin"  element={<AdminGuard component={AddminDashboard} />}>
            {/* products */}
            <Route path="" element={<OrdersList />}/>
            <Route path="topics/Create" element={<TopicsAdd />} />
            <Route path="add-product" element={<AddProduct />}/>
            <Route path="manage-products" element={<ManageStocks />}/>
            <Route path="products/edit/:id" element={<ProductUpdate />}/>
            {/* digital products */}
            <Route path="add-digital-product" element={<AddDigitalProduct />}/>
            <Route path="manage-digital-products" element={<ManageDigitalStocks />}/>
            <Route path="digital-products/edit/:id" element={<DigitalProductUpdate />}/>
            {/* coupons */}
            <Route path="add-coupon" element={<AddCoupon />}/>
            <Route path="manage-coupon" element={<ManageCoupons />} />
            <Route path="manage-coupon/edit/:code" element={ <UpdateCoupon />}/>
            {/* Category */}
            <Route path="category-to-add" element={<CategoryToAdd />}/>
            <Route path="add-category" element={<CategoryToAdd />} />
            <Route path="manage-category" element={<ManageCategories />}/>
            <Route path="edit-category/:id" element={<UpdateCategory />}/>
   
            <Route path="add-brand" element={ <AddBrand />}/>
            <Route path="all-brands" element={<BrandsList />} />

            <Route path="add-color" element={<AddColor />}/>
            <Route path="all-colors" element={<ColorsList />} />
            {/* Orders */}
            <Route path="manage-orders" element={<ManageOrders />} />
            <Route path="orders/:id" element={<UpdateOrders />}/>
            <Route path="customers"  element={<Customers />}/>
          </Route>


          {/* 404 page if none of the roots match the user request */}
          <Route path="*" element={<NotFoundPage />} />

        </Routes>

  );
}



export default App;
