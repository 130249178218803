import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Products from "./Products";
import baseURL from "../../../utils/baseURL";
import { fetchProductsAction } from "../../../redux/slices/products/productSlices";
import { fetchBrandsAction } from "../../../redux/slices/categories/brandsSlice";
import { fetchColorsAction } from "../../../redux/slices/categories/colorsSlice";
import LoadingComponent from "../../LoadingComp/LoadingComponent";
import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import NoDataFound from "../../NoDataFound/NoDataFound";
import { PageLayout } from "../../../components/page-layout";
import { Button, Offcanvas, Accordion, Form, Row, Col } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";

import HeroImage from "../../../components/hero";
import topicsImageHeader from "../../../static/topicsImage.jpg";

const allPrice = [
  { amount: "0 - 50" },
  { amount: "50 - 100" },
  { amount: "100 - 150" },
  { amount: "150 - 200" },
  { amount: "200 - 250" },
  { amount: "250 - 300" },
  { amount: "300 - 350" },
  { amount: "350 - 400" },
];

const sizeCategories = ["S", "M", "L", "XL", "XXL"];

export default function ProductsFilters() {
  // Dispatch
  const dispatch = useDispatch();
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const handleClose = () => setMobileFiltersOpen(false);
  const handleShow = () => setMobileFiltersOpen(true);

  // Filters state
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedPrices, setSelectedPrices] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);

  // Build the request URL based on selected filters
  const buildProductUrl = () => {
    let url = `${baseURL}/products?`;
    if (selectedBrands.length > 0) {
      url += `brand=${selectedBrands.join(",")}&`;
    }
    if (selectedSizes.length > 0) {
      url += `size=${selectedSizes.join(",")}&`;
    }
    if (selectedPrices.length > 0) {
      url += `price=${selectedPrices.join(",")}&`;
    }
    if (selectedColors.length > 0) {
      url += `color=${selectedColors.map((color) => color.name).join(",")}&`;
    }
    return url;
  };

  const productUrl = buildProductUrl();

  // Fetch products
  useEffect(() => {
    dispatch(fetchProductsAction({ url: productUrl }));
  }, [dispatch, selectedColors, selectedPrices, selectedBrands, selectedSizes]);

  // Get products store data
  const {
    products: { products },
    loading,
    error,
  } = useSelector((state) => state?.products);

  // Fetch brands
  useEffect(() => {
    dispatch(fetchBrandsAction({ url: `${baseURL}/brands` }));
  }, [dispatch]);

  const {
    brands: { brands },
  } = useSelector((state) => state?.brands);

  // Fetch colors
  useEffect(() => {
    dispatch(fetchColorsAction({ url: `${baseURL}/colors` }));
  }, [dispatch]);

  const {
    colors: { colors },
  } = useSelector((state) => state?.colors);

  // Toggle filter selections
  const toggleSelection = (selection, setSelection, value) => {
    if (selection.includes(value)) {
      setSelection(selection.filter((item) => item !== value));
    } else {
      setSelection([...selection, value]);
    }
  };

  // Render filters
  const renderFilters = () => (
    <>
      <div>
        <Accordion defaultActiveKey="0">
          {/* Colors Filter */}
          <Accordion.Item eventKey="0">
            <Accordion.Header>Colors</Accordion.Header>
            <Accordion.Body>
              <div>
                {colors?.map((colorOption) => (
                  <div key={colorOption?.id} className="mb-2">
                    <Form.Check
                      type="checkbox"
                      id={`color-${colorOption?.id}`}
                      name="colors"
                      checked={selectedColors.includes(colorOption)}
                      onChange={() => toggleSelection(selectedColors, setSelectedColors, colorOption)}
                      label={
                        <span
                          style={{
                            backgroundColor: colorOption?.name,
                            display: "inline-block",
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            border: "1px solid black",
                          }}
                        />
                      }
                    />
                  </div>
                ))}
              </div>
            </Accordion.Body>
          </Accordion.Item>

          {/* Price Filter */}
          <Accordion.Item eventKey="1">
            <Accordion.Header>Price</Accordion.Header>
            <Accordion.Body>
              {allPrice.map((priceRange) => (
                <Form.Check
                  key={priceRange.amount}
                  type="checkbox"
                  id={`price-${priceRange.amount}`}
                  name="price"
                  checked={selectedPrices.includes(priceRange.amount)}
                  label={`£ ${priceRange.amount}`}
                  onChange={() => toggleSelection(selectedPrices, setSelectedPrices, priceRange.amount)}
                />
              ))}
            </Accordion.Body>
          </Accordion.Item>

          {/* Brands Filter */}
          <Accordion.Item eventKey="2">
            <Accordion.Header>Brands</Accordion.Header>
            <Accordion.Body>
              {brands?.map((brandOption) => (
                <Form.Check
                  key={brandOption?.id}
                  type="checkbox"
                  id={`brand-${brandOption?.id}`}
                  name="brand"
                  checked={selectedBrands.includes(brandOption?.name)}
                  label={brandOption?.name}
                  onChange={() => toggleSelection(selectedBrands, setSelectedBrands, brandOption?.name)}
                />
              ))}
            </Accordion.Body>
          </Accordion.Item>

          {/* Sizes Filter */}
          <Accordion.Item eventKey="3">
            <Accordion.Header>Sizes</Accordion.Header>
            <Accordion.Body>
              {sizeCategories.map((sizeCategory) => (
                <Form.Check
                  key={sizeCategory}
                  type="checkbox"
                  id={`size-${sizeCategory}`}
                  name="size"
                  checked={selectedSizes.includes(sizeCategory)}
                  label={sizeCategory}
                  onChange={() => toggleSelection(selectedSizes, setSelectedSizes, sizeCategory)}
                />
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );

  return (
    <PageLayout>
            <div style={{width:"100%"}}>
      <HeroImage imagepath={topicsImageHeader} header="Shop" subheader="Welcome to our shop" pad="20px"></HeroImage>
      <Row>
        {/*<Col lg={3} className="d-none d-lg-block" style={{minHeight:"100vh"}}>
          {renderFilters()}
        </Col>*/}

        {/* Mobile Filters xs={12} className="d-block d-lg-none" */}
        <Col style={{width:"100%"}}>
          <Button
            variant=""
            onClick={handleShow}
            style={{ border: "none", fontSize: "1.4rem", padding: "10px" }}
          >
            Filters <FaFilter />
          </Button>
          <Offcanvas show={mobileFiltersOpen} onHide={handleClose} placement="start">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Filters</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>{renderFilters()}</Offcanvas.Body>
          </Offcanvas>
        </Col>
      </Row>
      <Row style={{minHeight: "100vh"}}>
        {/* Desktop Filters */}
        

        {/* Products Section lg={9} xs={12}*/}
        <Col >
          {loading ? (
            <LoadingComponent />
          ) : error ? (
            <ErrorMsg message={error?.message} />
          ) : products?.length <= 0 ? (
            <NoDataFound />
          ) : (
            <Products products={products} />
          )}
        </Col>
      </Row>
      </div>
    </PageLayout>
  );
}