import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { fetchBrandsAction } from "../../../redux/slices/categories/brandsSlice";
import { fetchCategoriesAction } from "../../../redux/slices/categories/categoriesSlice";
import { fetchColorsAction } from "../../../redux/slices/categories/colorsSlice";
import {
  createProductAction,
  fetchProductAction,
  updateProductAction,
} from "../../../redux/slices/products/productSlices";

import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import LoadingComponent from "../../LoadingComp/LoadingComponent";
import SuccessMsg from "../../SuccessMsg/SuccessMsg";
import { Button, Form, InputGroup, Col, Row, Container } from "react-bootstrap";

//animated components for react-select
const animatedComponents = makeAnimated();

export default function ProductUpdate() {
  //dispatch
  const dispatch = useDispatch();
  //get id from params
  const { id } = useParams();
  //fetch single product
  useEffect(() => {
    dispatch(fetchProductAction(id));
  }, [id, dispatch]);

  //Sizes
  const sizes = ["S", "M", "L", "XL", "XXL"];
  const [sizeOption, setSizeOption] = useState([]);
  const handleSizeChange = (sizes) => {
    setSizeOption(sizes);
  };

  //converted sizes
  const sizeOptionsCoverted = sizes?.map((size) => {
    return {
      value: size,
      label: size,
    };
  });

  //categories
  useEffect(() => {
    dispatch(fetchCategoriesAction());
  }, [dispatch]);
  //select data from store
  const { categories } = useSelector((state) => state?.categories?.categories);

  //brands
  useEffect(() => {
    dispatch(fetchBrandsAction());
  }, [dispatch]);
  //select data from store
  const {
    brands: { brands },
  } = useSelector((state) => state?.brands);

  //colors
  const [colorsOption, setColorsOption] = useState([]);

  const {
    colors: { colors },
  } = useSelector((state) => state?.colors);
  useEffect(() => {
    dispatch(fetchColorsAction());
  }, [dispatch]);
  
  

  const handleColorChange = (colors) => {
    setColorsOption(colors);
  };

  //converted colors
  const colorsCoverted = colors?.map((color) => {
    return {
      value: color?.name,
      label: color?.name,
    };
  });

  //get product from store
  const { product, isUpdated, loading, error } = useSelector(
    (state) => state?.products
  );

  useEffect(() => {
    if (product) {
      setFormData({
        name: product?.product?.name || "",
        description: product?.product?.description || "",
        category: product?.product?.category || "",
        sizes: product?.product?.sizes || [],
        brand: product?.product?.brand || "",
        colors: product?.product?.colors || [],
        price: product?.product?.price || "",
        totalQty: product?.product?.totalQty || "",
      });
  
      // Set initial size and color options
      setSizeOption(
        product?.product?.sizes?.map((size) => ({ value: size, label: size })) || []
      );
  
      setColorsOption(
        product?.product?.colors?.map((color) => ({ value: color, label: color })) || []
      );
    }
  }, [product]);

  //---form data---
  const [formData, setFormData] = useState({
    name: product?.product?.name,
    description: product?.product?.description,
    category: product?.product?.category,
    sizes: "",
    brand: product?.product?.brand,
    colors: "",
    price: product?.product?.price,
    totalQty: product?.product?.totalQty,
  });

  //onChange
  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //onSubmit
  const handleOnSubmit = (e) => {
    e.preventDefault();
    //dispatch
    dispatch(
      updateProductAction({
        ...formData,
        id,
        colors: colorsOption?.map((color) => color.label),
        sizes: sizeOption?.map((size) => size?.label),
      })
    );

    //reset form data
    setFormData({
      name: "",
      description: "",
      category: "",
      sizes: "",
      brand: "",
      colors: "",
      images: "",
      price: "",
      totalQty: "",
    });
  };

  if (loading) {
    return <LoadingComponent />;
  }  

  return (
    <Container className="py-5">
      {error && <ErrorMsg message={error?.message} />}
      {isUpdated && <SuccessMsg message="Product Updated Successfully" />}

      <Row className="justify-content-center">
        <Col xs={12} sm={8} md={6}>
          <h2 className="text-center">Update Product</h2>
          <p className="text-center text-muted">
            <strong>Manage Products</strong>
          </p>
          <div className="bg-white shadow rounded p-4">
            <Form onSubmit={handleOnSubmit}>
              <Form.Group controlId="productName">
                <Form.Label>Product Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData?.name}
                  onChange={handleOnChange}
                  required
                />
              </Form.Group>

              {/* size option */}
              <Form.Group controlId="selectSize">
                <Form.Label>Select Size</Form.Label>
                <Select
                  components={animatedComponents}
                  isMulti
                  name="sizes"
                  options={sizeOptionsCoverted}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  closeMenuOnSelect={false}
                  onChange={(item) => handleSizeChange(item)}
                />
              </Form.Group>

              {/* Select category */}
              <Form.Group controlId="selectCategory">
                <Form.Label>Select Category</Form.Label>
                <Form.Control
                  as="select"
                  name="category"
                  value={formData.category}
                  onChange={handleOnChange}
                >
                  <option>-- Select Category --</option>
                  {categories?.map((category) => (
                    <option key={category?._id} value={category?.name}>
                      {category.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              {/* Select Brand */}
              <Form.Group controlId="selectBrand">
                <Form.Label>Select Brand</Form.Label>
                <Form.Control
                  as="select"
                  name="brand"
                  value={formData.brand}
                  onChange={handleOnChange}
                >
                  <option>-- Select Brand --</option>
                  {brands?.map((brand) => (
                    <option key={brand?._id} value={brand?.name}>
                      {brand.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              {/* Select Color */}
              <Form.Group controlId="selectColor">
                <Form.Label>Select Color</Form.Label>
                <Select
                  components={animatedComponents}
                  isMulti
                  name="colors"
                  options={colorsCoverted}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  closeMenuOnSelect={false}
                  onChange={(e) => handleColorChange(e)}
                />
              </Form.Group>

              {/* Price */}
              <Form.Group controlId="price">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  name="price"
                  value={formData.price}
                  onChange={handleOnChange}
                  required
                />
              </Form.Group>

              {/* Quantity */}
              <Form.Group controlId="totalQty">
                <Form.Label>Total Quantity</Form.Label>
                <Form.Control
                  type="number"
                  name="totalQty"
                  value={formData.totalQty}
                  onChange={handleOnChange}
                  required
                />
              </Form.Group>

              {/* Description */}
              <Form.Group controlId="description">
                <Form.Label>Add Product Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="description"
                  value={formData.description}
                  onChange={handleOnChange}
                />
              </Form.Group>

              {/* Submit Button */}
              <Button
                variant="primary"
                type="submit"
                className="w-100"
                disabled={loading}
              >
                {loading ? "Updating..." : "Update Product"}
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
