import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { PageLoader } from "../page-loader";
import {useAuth0} from "@auth0/auth0-react";
import NotFoundPage from "../../pages/error/404";
import baseURL from "../../utils/baseURL";
import axios from "axios";

export const AdminGuard = ({ component }) => {
  const { getAccessTokenSilently } = useAuth0();

  const GetUserInfo = async () => {
    const token = await getAccessTokenSilently()
    const { data } = await axios.get(`${baseURL}/users/retrieve`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    });
      if(data.isAdmin){
          return <NotFoundPage />;
      }
  }

  useEffect(() => {
    GetUserInfo();
  }, [])

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
        <PageLoader />
      </div>
    ),
  });

  return <Component />;
};