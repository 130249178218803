import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Form,Button,FormGroup,FormLabel,FormControl,Container} from "react-bootstrap";
import { fetchBrandsAction } from "../../../redux/slices/categories/brandsSlice";
import { fetchCategoriesAction } from "../../../redux/slices/categories/categoriesSlice";
import { createDigitalProductAction } from "../../../redux/slices/products/digitalProducts";
import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import LoadingComponent from "../../LoadingComp/LoadingComponent";
import SuccessMsg from "../../SuccessMsg/SuccessMsg";

export default function AddDigitalProduct() {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [fileErrs, setFileErrs] = useState([]);

  const fileHandleChange = (event) => {
    const newFiles = Array.from(event.target.files);
    const newErrs = [];
    newFiles.forEach((file) => {
      if (file?.size > 1000000) {
        newErrs.push(`${file?.name} is too large`);
      }
      if (!file?.type?.startsWith("image/")) {
        newErrs.push(`${file?.name} is not an image`);
      }
    });
    setFiles(newFiles);
    setFileErrs(newErrs);
  };

  useEffect(() => {
    dispatch(fetchCategoriesAction());
    dispatch(fetchBrandsAction());
  }, [dispatch]);

  const { categories } = useSelector((state) => state?.categories?.categories);
  const { brands: { brands } } = useSelector((state) => state?.brands);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    category: "",
    brand: "",
    images: "",
    price: "",
    fileLocation: ""
  });

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const { isAdded, loading, error } = useSelector((state) => state?.digitalProducts);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createDigitalProductAction({
        ...formData,
        files,
      })
    );

    setFormData({
      name: "",
      description: "",
      category: "",
      brand: "",
      images: "",
      price: "",
      fileLocation: "",
    });
  };

  return (
    <Container>
      {error && <ErrorMsg message={error?.message} />}
      {fileErrs.length > 0 && <ErrorMsg message="File too large or invalid type" />}
      {isAdded && <SuccessMsg message="Product Added Successfully" />}
      <h2 className="text-center my-4">Create New Product</h2>
      <Form onSubmit={handleOnSubmit}>
        <FormGroup>
          <FormLabel>Digital Product Name</FormLabel>
          <FormControl
            name="name"
            value={formData.name}
            onChange={handleOnChange}
            placeholder="Enter product name"
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>Digital Product File Location</FormLabel>
          <FormControl
            name="fileLocation"
            value={formData.fileLocation}
            onChange={handleOnChange}
            placeholder="https://example.com/file"
          />
        </FormGroup>
        
        <FormGroup>
          <FormLabel>Select Category</FormLabel>
          <FormControl
            as="select"
            name="category"
            value={formData.category}
            onChange={handleOnChange}
          >
            <option>-- Select Category --</option>
            {categories?.map((category) => (
              <option key={category?._id} value={category?.name}>
                {category.name}
              </option>
            ))}
          </FormControl>
        </FormGroup>
        <FormGroup>
          <FormLabel>Select Brand</FormLabel>
          <FormControl
            as="select"
            name="brand"
            value={formData.brand}
            onChange={handleOnChange}
          >
            <option>-- Select Brand --</option>
            {brands?.map((brand) => (
              <option key={brand?._id} value={brand?.name}>
                {brand.name}
              </option>
            ))}
          </FormControl>
        </FormGroup>
       
        <FormGroup>
          <FormLabel>Upload Images</FormLabel>
          <FormControl
            type="file"
            multiple
            onChange={fileHandleChange}
          />
          <small className="text-muted">PNG, JPG, GIF up to 1MB</small>
        </FormGroup>
        <FormGroup>
          <FormLabel>Price</FormLabel>
          <FormControl
            type="number"
            name="price"
            value={formData.price}
            onChange={handleOnChange}
          />
        </FormGroup>
        
        <FormGroup>
          <FormLabel>Product Description</FormLabel>
          <FormControl
            as="textarea"
            rows={4}
            name="description"
            value={formData.description}
            onChange={handleOnChange}
          />
        </FormGroup>
        <Button type="submit" disabled={fileErrs.length > 0} variant="primary">
          {loading ? <LoadingComponent /> : "Add Digital Product"}
        </Button>
      </Form>
    </Container>
  );
}
