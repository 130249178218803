import { useState } from "react";
import { Button, Form, Container, Row, Col, Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingComponent from "../../LoadingComp/LoadingComponent";
import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import SuccessMsg from "../../SuccessMsg/SuccessMsg";
import { createCouponAction } from "../../../redux/slices/coupons/couponsSlice";
import { useDispatch, useSelector } from "react-redux";

export default function AddCoupon() {
  //dispatch
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [formData, setFormData] = useState({
    code: "",
    discount: "",
  });

  //---onHandleChange---
  const onHandleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  //---onHandleSubmit---
  const onHandleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createCouponAction({
        discount: formData?.discount,
        code: formData?.code,
        startDate,
        endDate,
      })
    );
    //reset form
    setFormData({
      code: "",
      discount: "",
    });
  };
  //---coupon from store---
  const { loading, isAdded, error, coupon } = useSelector(
    (state) => state?.coupons
  );

  return (
    <Container className="py-5">
      {error && <ErrorMsg message={error?.message} />}
      {isAdded && (
        <SuccessMsg message="Bravo, coupon created successfully" />
      )}
      <Row className="justify-content-center">
        <Col xs={12} sm={8} md={6}>
          <Card className="shadow">
            <Card.Body>
              <h2 className="text-center">Add New Coupon</h2>
              <Form onSubmit={onHandleSubmit}>
                <Form.Group controlId="code" className="py-2">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="code"
                    value={formData.code}
                    onChange={onHandleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="discount" className="py-2">
                  <Form.Label>Discount (in %)</Form.Label>
                  <Form.Control
                    type="number"
                    name="discount"
                    value={formData.discount}
                    onChange={onHandleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="startDate" className="py-2">
                  <Form.Label>Start Date</Form.Label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                  />
                </Form.Group>

                <Form.Group controlId="endDate" className="py-2">
                  <Form.Label>End Date</Form.Label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                  />
                </Form.Group>

                <div className="text-center">
                  {loading ? (
                    <LoadingComponent />
                  ) : (
                    <Button
                      type="submit"
                      variant="primary"
                      className="w-100"
                    >
                      Add Coupon
                    </Button>
                  )}
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
