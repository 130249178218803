import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { BsTrash } from 'react-icons/bs';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


export default function DisplayAllTopics(pDeleteId) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { getAccessTokenSilently } = useAuth0();
    const [deleteId] = useState(pDeleteId.deleteId)
    const [submittingDelete, setSubmittingDelete] = useState(false)

    const navigate = useNavigate();

    const deleteResource = async() => {

        try {
            const accessToken = await getAccessTokenSilently();
            setSubmittingDelete(true)
            await axios.delete(`${process.env.REACT_APP_API_SERVER_URL}/v1/items/delete/${deleteId}`, {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                }
            })
            navigate("/resources");


        } catch(ex) {
          setSubmittingDelete(false)
          handleClose();
          alert("Item could not be deleted please try again later.")
        }
    }

  return (
    <>
      <Button className="btn btn-danger" onClick={handleShow}>
        <BsTrash />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Your Resource</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete your resource? This process cannot be undone.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={deleteResource} disabled={submittingDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


