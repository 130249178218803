import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchProductsAction } from "../../../redux/slices/products/productSlices";
import baseURL from "../../../utils/baseURL";
import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import LoadingComponent from "../../LoadingComp/LoadingComponent";
import NoDataFound from "../../NoDataFound/NoDataFound";
import { Table, Button, Container, Row, Col, Image, Badge } from "react-bootstrap";

export default function ManageStocks() {
  const deleteProductHandler = (id) => {};
  const productUrl = `${baseURL}/products`;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchProductsAction({
        url: productUrl,
      })
    );
  }, [dispatch]);

  const {
    products: { products },
    loading,
    error,
  } = useSelector((state) => state?.products);

  return (
    <Container className="mt-4">
      <Row className="mb-3 align-items-center">
        <Col>
          <h1>Product List - [{products?.length}]</h1>
          <p>List of all the products in your account including their name, title.</p>
        </Col>
        <Col className="text-end">
          <Button variant="primary">Add New Product</Button>
        </Col>
      </Row>

      {loading ? (
        <LoadingComponent />
      ) : error ? (
        <ErrorMsg message={error?.message} />
      ) : products?.length <= 0 ? (
        <NoDataFound />
      ) : (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Category</th>
              <th>Status</th>
              <th>Total Qty</th>
              <th>Total Sold</th>
              <th>QTY Left</th>
              <th>Price</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {products?.map((product) => (
              <tr key={product._id}>
                <td>
                  <div className="d-flex align-items-center">
                    <Image
                      src={product?.images[0]}
                      alt={product?.name}
                      roundedCircle
                      width={40}
                      height={40}
                      className="me-2"
                    />
                    <div>
                      <div>{product.name}</div>
                      <small className="text-muted">{product?.brand}</small>
                    </div>
                  </div>
                </td>
                <td>{product?.category}</td>
                <td>
                  {product?.qtyLeft < 0 ? (
                    <Badge bg="danger">Out of Stock</Badge>
                  ) : (
                    <Badge bg="success">In Stock</Badge>
                  )}
                </td>
                <td>{product?.totalQty}</td>
                <td>{product?.totalSold}</td>
                <td>{product?.qtyLeft}</td>
                <td>{product?.price}</td>
                <td>
                  <Link to={`/admin/products/edit/${product._id}`} className="text-primary">
                    Edit
                  </Link>
                </td>
                <td>
                  <Button
                    variant="link"
                    className="text-danger p-0"
                    onClick={() => deleteProductHandler(product._id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
}
