import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createReviewAction } from "../../../redux/slices/reviews/reviewsSlice";
import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import LoadingComponent from "../../LoadingComp/LoadingComponent";
import SuccessMsg from "../../SuccessMsg/SuccessMsg";
import { Form, Button, Spinner, Card } from "react-bootstrap";
import { PageLayout } from "../../../components/page-layout";

export default function AddReview() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    rating: "",
    message: "",
  });

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createReviewAction({
        id,
        message: formData.message,
        rating: formData.rating,
      })
    );
  };

  const { loading, error, isAdded } = useSelector((state) => state?.reviews);

  return (
    <PageLayout>
      {error && <ErrorMsg message={error?.message} />}
      {isAdded && <SuccessMsg message="Thanks for the review" />}
      <div className="d-flex flex-column align-items-center py-4">
        <Card className="w-100" style={{ maxWidth: "400px" }}>
          <Card.Body>
            <h2 className="text-center mb-4">Add Your Review</h2>
            
            <Form onSubmit={handleOnSubmit}>
              <Form.Group className="mb-3" controlId="rating">
                <Form.Label>Rating</Form.Label>
                <Form.Select
                  name="rating"
                  value={formData.rating}
                  onChange={handleOnChange}
                >
                  <option value="">Select a rating</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="message">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="message"
                  value={formData.message}
                  onChange={handleOnChange}
                  placeholder="Write your review here"
                />
              </Form.Group>

              <div className="d-grid gap-2">
                {loading ? (
                  <Button variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading...
                  </Button>
                ) : (
                  <Button type="submit" variant="success">
                    Add New Review
                  </Button>
                )}
                <Button variant="danger" type="button">
                  I have Changed my mind
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </PageLayout>
  );
}
