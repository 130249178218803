import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  Form,
  Button,
  FormGroup,
  FormLabel,
  FormControl,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { fetchBrandsAction } from "../../../redux/slices/categories/brandsSlice";
import { fetchCategoriesAction } from "../../../redux/slices/categories/categoriesSlice";
import { fetchColorsAction } from "../../../redux/slices/categories/colorsSlice";
import { createProductAction } from "../../../redux/slices/products/productSlices";

import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import LoadingComponent from "../../LoadingComp/LoadingComponent";
import SuccessMsg from "../../SuccessMsg/SuccessMsg";

const animatedComponents = makeAnimated();

export default function AddProduct() {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [fileErrs, setFileErrs] = useState([]);

  const fileHandleChange = (event) => {
    const newFiles = Array.from(event.target.files);
    const newErrs = [];
    newFiles.forEach((file) => {
      if (file?.size > 1000000) {
        newErrs.push(`${file?.name} is too large`);
      }
      if (!file?.type?.startsWith("image/")) {
        newErrs.push(`${file?.name} is not an image`);
      }
    });
    setFiles(newFiles);
    setFileErrs(newErrs);
  };

  const sizes = ["S", "M", "L", "XL", "XXL"];
  const [sizeOption, setSizeOption] = useState([]);

  const handleSizeChange = (sizes) => {
    setSizeOption(sizes);
  };

  const sizeOptionsConverted = sizes?.map((size) => ({ value: size, label: size }));

  useEffect(() => {
    dispatch(fetchCategoriesAction());
    dispatch(fetchBrandsAction());
    dispatch(fetchColorsAction());
  }, [dispatch]);

  const { categories } = useSelector((state) => state?.categories?.categories);
  const { brands: { brands } } = useSelector((state) => state?.brands);
  const { colors: { colors } } = useSelector((state) => state?.colors);

  const [colorsOption, setColorsOption] = useState([]);
  const handleColorChange = (colors) => setColorsOption(colors);
  const colorsConverted = colors?.map((color) => ({ value: color?.name, label: color?.name }));

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    category: "",
    sizes: "",
    brand: "",
    colors: "",
    images: "",
    price: "",
    totalQty: "",
  });

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const { isAdded, loading, error } = useSelector((state) => state?.products);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createProductAction({
        ...formData,
        files,
        colors: colorsOption?.map((color) => color.label),
        sizes: sizeOption?.map((size) => size?.label),
      })
    );

    setFormData({
      name: "",
      description: "",
      category: "",
      sizes: "",
      brand: "",
      colors: "",
      images: "",
      price: "",
      totalQty: "",
    });
  };

  return (
    <Container>
      {error && <ErrorMsg message={error?.message} />}
      {fileErrs.length > 0 && <ErrorMsg message="File too large or invalid type" />}
      {isAdded && <SuccessMsg message="Product Added Successfully" />}
      <h2 className="text-center my-4">Create New Product</h2>
      <Form onSubmit={handleOnSubmit}>
        <FormGroup>
          <FormLabel>Product Name</FormLabel>
          <FormControl
            name="name"
            value={formData.name}
            onChange={handleOnChange}
            placeholder="Enter product name"
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Select Size</FormLabel>
          <Select
            components={animatedComponents}
            isMulti
            name="sizes"
            options={sizeOptionsConverted}
            onChange={handleSizeChange}
            isClearable
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Select Category</FormLabel>
          <FormControl
            as="select"
            name="category"
            value={formData.category}
            onChange={handleOnChange}
          >
            <option>-- Select Category --</option>
            {categories?.map((category) => (
              <option key={category?._id} value={category?.name}>
                {category.name}
              </option>
            ))}
          </FormControl>
        </FormGroup>
        <FormGroup>
          <FormLabel>Select Brand</FormLabel>
          <FormControl
            as="select"
            name="brand"
            value={formData.brand}
            onChange={handleOnChange}
          >
            <option>-- Select Brand --</option>
            {brands?.map((brand) => (
              <option key={brand?._id} value={brand?.name}>
                {brand.name}
              </option>
            ))}
          </FormControl>
        </FormGroup>
        <FormGroup>
          <FormLabel>Select Color</FormLabel>
          <Select
            components={animatedComponents}
            isMulti
            name="colors"
            options={colorsConverted}
            onChange={handleColorChange}
            isClearable
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Upload Images</FormLabel>
          <FormControl
            type="file"
            multiple
            onChange={fileHandleChange}
          />
          <small className="text-muted">PNG, JPG, GIF up to 1MB</small>
        </FormGroup>
        <FormGroup>
          <FormLabel>Price</FormLabel>
          <FormControl
            type="number"
            name="price"
            value={formData.price}
            onChange={handleOnChange}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Total Quantity</FormLabel>
          <FormControl
            type="number"
            name="totalQty"
            value={formData.totalQty}
            onChange={handleOnChange}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Product Description</FormLabel>
          <FormControl
            as="textarea"
            rows={4}
            name="description"
            value={formData.description}
            onChange={handleOnChange}
          />
        </FormGroup>
        <Button type="submit" disabled={fileErrs.length > 0} variant="primary">
          {loading ? <LoadingComponent /> : "Add Product"}
        </Button>
      </Form>
    </Container>
  );
}
