import { Outlet } from "react-router-dom";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
export default function AddminDashboard() {
  return (
    <>
        <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
            <Navbar.Brand as={Link} to="/admin">Admin Dashboard</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
                {/* Orders Section */}
                <Nav.Link as={Link} to="/admin/manage-orders">Manage Orders</Nav.Link>
                <Nav.Link as={Link} to="/admin/customers">Customers</Nav.Link>
                
                {/* Products Section */}
                <NavDropdown title="Products" id="products-dropdown">
                <NavDropdown.Item as={Link} to="/admin/add-product">Add Product</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/admin/manage-products">Manage Products</NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title="DigitalProducts" id="digital-products-dropdown">
                <NavDropdown.Item as={Link} to="/admin/add-digital-product">Add Digital Product</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/admin/manage-digital-products">Manage Digital Products</NavDropdown.Item>
                </NavDropdown>

                {/* Coupons Section */}
                <NavDropdown title="Coupons" id="coupons-dropdown">
                <NavDropdown.Item as={Link} to="/admin/add-coupon">Add Coupon</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/admin/manage-coupon">Manage Coupons</NavDropdown.Item>
                
                </NavDropdown>

                <NavDropdown title="Category" id="category-dropdown">
                <NavDropdown.Item as={Link} to="/admin/add-category">Add Category</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/admin/manage-category">Manage Categories</NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title="Brand" id="brand-dropdown">
                <NavDropdown.Item as={Link} to="/admin/add-brand">Add Brand</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/admin/all-brands">All Brands</NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title="Color" id="color-dropdown">
                <NavDropdown.Item as={Link} to="/admin/add-color">Add Color</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/admin/all-colors">All Colors</NavDropdown.Item>
                </NavDropdown>
                

            </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>
      <Outlet />
    </>
  );
}
