import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  deleteCouponAction,
  fetchCouponsAction,
} from "../../../redux/slices/coupons/couponsSlice";
import { Button, Table, Container, Row, Col, Card } from "react-bootstrap";
import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import LoadingComponent from "../../LoadingComp/LoadingComponent";
import NoDataFound from "../../NoDataFound/NoDataFound";

export default function ManageCoupons() {
  //dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCouponsAction());
  }, [dispatch]);
  //get coupons
  const { coupons, loading, error } = useSelector((state) => state?.coupons);

  //---deleteHandler---
  const deleteCouponHandler = (id) => {
    dispatch(deleteCouponAction(id));
    //reload
    window.location.reload();
  };

  return (
    <Container className="py-5">
      <Row className="mb-4">
        <Col>
          <h1>Manage Coupons - [{coupons?.coupons?.length}]</h1>
          <p>List of all coupons in the system</p>
        </Col>
        <Col className="text-end">
          <Button variant="primary">Add New Coupon</Button>
        </Col>
      </Row>

      {loading ? (
        <LoadingComponent />
      ) : error ? (
        <ErrorMsg message={error?.message || "Something went wrong, please try again"} />
      ) : coupons?.coupons?.length <= 0 ? (
        <NoDataFound />
      ) : (
        <Card>
          <Card.Body>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Percentage (%)</th>
                  <th>Status</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Days Left</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {coupons?.coupons?.map((coupon) => (
                  <tr key={coupon._id}>
                    <td>{coupon?.code}</td>
                    <td>{coupon?.discount}</td>
                    <td>
                      {coupon?.isExpired ? (
                        <span className="badge bg-danger">Expired</span>
                      ) : (
                        <span className="badge bg-success">Active</span>
                      )}
                    </td>
                    <td>{new Date(coupon.startDate)?.toLocaleDateString()}</td>
                    <td>{new Date(coupon.endDate)?.toLocaleDateString()}</td>
                    <td>{coupon?.daysLeft}</td>
                    <td>
                      <Link to={`/admin/manage-coupon/edit/${coupon.code}`}>
                        <Button variant="outline-primary" size="sm">
                          Edit
                        </Button>
                      </Link>
                    </td>
                    <td>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => deleteCouponHandler(coupon?._id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
    </Container>
  );
}
