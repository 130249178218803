import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Form, Container, Row, Col, Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingComponent from "../../LoadingComp/LoadingComponent";
import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import SuccessMsg from "../../SuccessMsg/SuccessMsg";
import { useDispatch, useSelector } from "react-redux";
import { fetchCouponAction, updateCouponAction } from "../../../redux/slices/coupons/couponsSlice";

export default function UpdateCoupon() {
  // get coupon from URL
  const { code } = useParams();
  // dispatch
  const dispatch = useDispatch();
  
  // fetch coupon
  useEffect(() => {
    dispatch(fetchCouponAction(code));
  }, [code, dispatch]);

  const { coupon, loading, error, isUpdated } = useSelector((state) => state?.coupons);
  
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  // handle form data
  const [formData, setFormData] = useState({
    code: coupon?.coupon?.code,
    discount: coupon?.coupon?.discount,
  });

  useEffect(() => {
    if (coupon) {
      setFormData({
        code: coupon?.coupon?.code || "",
        discount: coupon?.coupon?.discount || "",
      });
      if (coupon?.coupon?.startDate) setStartDate(new Date(coupon?.coupon?.startDate));
      if (coupon?.coupon?.endDate) setEndDate(new Date(coupon?.coupon?.endDate));
    }
  }, [coupon]);

  // onHandleChange
  const onHandleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // onHandleSubmit
  const onHandleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateCouponAction({
        id: coupon?.coupon?._id,
        code: formData?.code,
        discount: formData?.discount,
        startDate,
        endDate,
      })
    );
    // reset form
    setFormData({
      code: "",
      discount: "",
    });
  };

  return (
    <Container className="py-5">
      {isUpdated && <SuccessMsg message="Coupon Updated successfully" />}
      <Row className="mb-4 justify-content-center">
        <Col sm={12} md={6} lg={4}>
          <Card>
            <Card.Body>
              <Card.Title className="text-center">Update Coupon</Card.Title>
              {error ? (
                <ErrorMsg message={error?.message} />
              ) : (
                <Form onSubmit={onHandleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="code"
                      value={formData?.code}
                      onChange={onHandleChange}
                      placeholder="Coupon Code"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Discount (%)</Form.Label>
                    <Form.Control
                      type="number"
                      name="discount"
                      value={formData?.discount}
                      onChange={onHandleChange}
                      placeholder="Discount Percentage"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Start Date</Form.Label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>End Date</Form.Label>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                    />
                  </Form.Group>

                  <div className="d-grid">
                    {loading ? (
                      <LoadingComponent />
                    ) : (
                      <Button variant="primary" type="submit">
                        Update Coupon
                      </Button>
                    )}
                  </div>
                </Form>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
